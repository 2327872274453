import React, { useState } from "react";
import "./App.css";
import ReactPlayer from "react-player";
import { data } from "./VIDYAS";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useWindowSize } from "./hooks/useWindowSize";
import ReactGA from "react-ga";

function App() {
  const [video, setVideo] = useState(
    data[Math.floor(Math.random() * data.length)]
  );
  const changeVideo = () => {
    setVideo(data[Math.floor(Math.random() * data.length)]);
    ReactGA.event({
      category: "User",
      action: "Hit Random Button",
    });
  };
  const size = useWindowSize();

  function initializeReactGA() {
    ReactGA.initialize("UA-164521856-1");
    ReactGA.pageview("/");
  }

  initializeReactGA();
  return (
    <div
      className="App"
      style={{ backgroundColor: "#313045", minHeight: "100vh" }}
    >
      <h1 style={{ color: "white", paddingTop: "20px" }}>
        The Yetson Collection
      </h1>

      <Container
        style={{
          backgroundColor: "#313045",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          marginTop: "25px",
          flexDirection: "row",
        }}
      >
        <Row style={{ padding: 0, justifyContent: "center" }}>
          <Col style={{ padding: 0, justifyContent: "center" }}>
            <div
              style={{
                margin: "0 auto",
                width: size.width > 640 ? 640 : size.width,
                height: size.width > 640 ? 360 : size.width * 0.5625,
                justifyContent: "center",
              }}
            >
              <ReactPlayer
                style={{ justifyContent: "center" }}
                url={video}
                playing
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    embedOptions: { showinfo: 0 },
                    playerVars: {
                      showinfo: 0,
                      autoplay: 1,
                      controls: 1,
                      fs: 1,
                      frameborder: 0,
                    },
                  },
                }}
              />
            </div>
            <Button
              style={{
                marginTop: "50px",
                width: "50vw",
                maxWidth: "640px",
                minWidth: "300px",
              }}
              onClick={changeVideo}
            >
              Random Video
            </Button>
          </Col>
        </Row>
      </Container>
      <p
        style={{
          color: "white",
        }}
      >
        Want to submit a video? I don't care.
      </p>
    </div>
  );
}

export default App;
