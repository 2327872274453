export const data = [
  "https://www.youtube.com/watch?v=psiyI6jVpKI&feature=youtu.be",
  "https://www.youtube.com/watch?v=NWxISwEBU0U",
  "https://www.youtube.com/watch?v=xrvs420WeoI",
  "https://www.youtube.com/watch?v=W_DU6yd4H6o",
  "https://youtu.be/ryWMekhEKOo",
  "https://www.youtube.com/watch?v=YQ-ZWsVeoKI",
  "https://www.youtube.com/watch?v=qHwvCekm2qg",
  "https://www.youtube.com/watch?v=uBYy7yg3Bd0&feature=youtu.be&ab_channel=Epicleptic",
  "https://www.youtube.com/watch?v=aFbuSShYKpc",
  "https://www.youtube.com/watch?v=9rSBmOgpcDE",
  "https://www.youtube.com/watch?v=033FInn1wH8",
  "https://www.youtube.com/watch?v=VAm89b8ojwo",
  "https://www.youtube.com/watch?v=rDynqsGrOvg&feature=youtu.be&t=9",
  "https://www.youtube.com/watch?v=Npx08EEXJdc&feature=youtu.be",
  "https://www.youtube.com/watch?v=oCS6G0wLsmA",
  "https://youtu.be/lLKAICrfaOg?t=7s",
  "https://www.youtube.com/watch?v=fB49UJmpEFA",
  "https://youtu.be/LjBUZ43bLjE",
  "https://www.youtube.com/watch?v=hBKkW6NBlpA&feature=youtu.be",
  "https://www.youtube.com/watch?v=hz4Lj_Iok5o",
  "https://youtu.be/ccgkxP-4tVE",
  "https://www.youtube.com/watch?v=lejsrzNzZ40",
  "https://www.youtube.com/watch?v=XDwPk7lMOps",
  "https://youtu.be/NzA2cu-VR9U",
  "https://www.youtube.com/watch?v=L--zpXSy5CI",
  "https://www.youtube.com/watch?v=WTW7uNXmRz0&feature=youtu.be",
  "https://www.youtube.com/watch?v=TRNSKN3eE8c&feature=youtu.be",
  "https://www.youtube.com/watch?v=_dNJisv6A9Y",
  "https://www.youtube.com/watch?v=b9dGG6zVzHs",
  "https://www.youtube.com/watch?v=wSv8SKVGhWA",
  "https://www.youtube.com/watch?v=tAECKBCBJZk",
  "https://www.youtube.com/watch?v=PRiAM_jjZkI&feature=youtu.be&t=2",
  "https://www.youtube.com/watch?v=BeA3B0B0MG8",
  "https://www.youtube.com/watch?v=Q4ZhtkEENL0",
  "https://www.youtube.com/watch?v=1byycwl8qgc",
  "https://youtu.be/doGFy3aQbbg?t=136",
  "https://www.youtube.com/watch?v=q5BT_6p4OHA",
  "https://youtu.be/yoHfBP5FFz4",
  "https://youtu.be/bNDpstGdTu8",
  "https://www.youtube.com/watch?v=yQB7woZyxjU",
  "https://www.youtube.com/watch?v=mlg8g5c7ty4",
  "https://www.youtube.com/watch?v=5Zr6pd-tceo&feature=youtu.be",
  "https://m.youtube.com/watch?v=VTO5yiN1b-I",
  "https://www.youtube.com/watch?v=4GSo1kTXpHE",
  "https://youtu.be/zsYBArQECBg",
  "https://youtu.be/wphawoHiUUg?t=20s",
  "https://www.youtube.com/watch?v=rAdNgkj5uHo",
  "https://youtu.be/JrjIhPk7O2Y",
  "https://www.youtube.com/watch?v=ezZ6T0NYdSI",
  "https://www.youtube.com/watch?v=Vbn9WvTNm8I",
  "https://www.youtube.com/watch?v=IwnoW6g-wtA",
  "https://youtu.be/px4oAEOi06s",
  "https://youtu.be/b_DaKQkuRxo",
  "https://www.youtube.com/watch?v=PoJt-ndMotw&feature=youtu.be&t=6",
  "https://www.youtube.com/watch?v=oONAwBtltR4&feature=youtu.be",
  "https://youtu.be/sowwq6w5uks",
  "https://www.youtube.com/watch?v=YJj2jf0HTPg",
  "http://youtu.be/NcWxWNRk9oM",
  "https://www.youtube.com/watch?v=a4V3bM6ucYM",
  "http://youtu.be/epg3g79zd2I",
  "https://www.youtube.com/watch?v=yipRNl6HRgo",
  "https://youtu.be/RsM9n2Nax-c",
  "https://www.youtube.com/watch?v=0_7WwPkqqvA",
  "https://www.youtube.com/watch?v=b9yL5usLFgY",
  "https://www.youtube.com/watch?v=aGqPr8dH1E4",
  "https://youtu.be/7zCK67dvrNo",
  "https://youtu.be/v-RE7RUzjf8",
  "https://youtu.be/uoNcGoCe6Ns",
  "https://www.youtube.com/watch?v=QK7oacKDt88",
  "https://www.youtube.com/watch?v=p-R1O7FN3yI",
  "https://www.youtube.com/watch?v=iB6uL_3_mBA",
  "https://youtu.be/3cc12dU1u0Y?t=35",
  "https://www.youtube.com/watch?v=7EJdxTTwi7Y",
  "https://www.youtube.com/watch?v=sjS5o8tgebA",
  "https://youtu.be/kM6gCqhtxyQ",
  "https://www.youtube.com/watch?v=MtbNY1QDoOc",
  "https://www.youtube.com/watch?v=_h8GxzNvB3A",
  "https://youtu.be/6Z8i7tBFJ34",
  "https://www.youtube.com/watch?v=9mkAjryORwc",
  "https://www.youtube.com/watch?v=wy_78NQNMCs",
  "https://www.youtube.com/watch?v=ZjNPmcC1AL4",
  "https://www.youtube.com/watch?v=kgQTI9NT7d8",
  "https://www.youtube.com/watch?v=nBbmfUKKZDc",
  "https://www.youtube.com/watch?v=1YXvX95ox68",
  "https://www.youtube.com/watch?v=4mn_V-n-dW0",
  "https://www.youtube.com/watch?v=7Zpc-HIqz2g",
  "https://www.youtube.com/watch?v=Tr2UF9CERoI",
  "https://www.youtube.com/watch?v=Z9LlEIDJL08",
  "https://www.youtube.com/watch?v=byOw4AYd7-8&t=1m53s",
  "https://www.youtube.com/watch?v=rduCZMdMAk4&nohtml5=False",
  "https://www.youtube.com/watch?v=CRv9OsH433M",
  "https://www.youtube.com/watch?v=zTJm_7yJn3c&feature=youtu.be",
  "https://youtu.be/GDhZmKCcvzM",
  "https://www.youtube.com/watch?v=Lt-U_t2pUHI&feature=youtu.be",
  "https://www.youtube.com/watch?v=afEEmz3XDPw",
  "https://www.youtube.com/watch?v=THH7P08zuhU",
  "https://www.youtube.com/watch?v=MKrpadhcC8g&feature=youtu.be",
  "https://youtu.be/i6UPykaA9GM",
  "https://youtu.be/7NPdVpx5CgE",
  "https://www.youtube.com/watch?v=ex4ND_mbkw4",
  "https://youtu.be/gijkklRI400",
  "https://youtu.be/rj3ub9f0fAk",
  "https://youtu.be/meTZQRd31kI",
  "https://youtu.be/ss5I1tQxycw",
  "https://youtu.be/aIYaTBvij14",
  "https://youtu.be/neV5NrTnLrA",
  "https://youtu.be/DWQMOUmb3Ng",
  "https://youtu.be/kjYw26PjGw0",
  "https://youtu.be/0QaAKi0NFkA",
  "https://youtu.be/VYHAU9T6U6Q",
  "https://www.youtube.com/watch?v=TjXmf2zCP2g",
  "https://youtu.be/SbD1HAGtWQw",
  "https://www.youtube.com/watch?v=hFHucr6s8ks",
  "https://www.youtube.com/watch?v=jtSwuyLOBLE",
  "https://youtu.be/JnD3p8YzTzY",
  "https://www.youtube.com/watch?v=13pgxOCHKh0",
  "https://www.youtube.com/watch?v=HAfEPy8MgrY&ab_channel=ReecardFarch%C3%A9",
  "https://youtu.be/VwxLKHmYOBk",
  "https://www.youtube.com/watch?v=2w8wqU9TrkA&feature=youtu.be&t=2",
  "https://www.youtube.com/watch?v=3c9m3DvlWsg",
  "https://www.youtube.com/watch?v=HJr6H6VB35c&feature=youtu.be",
  "https://www.youtube.com/watch?v=KVRkgg5L_lU&feature=youtu.be",
  "https://youtu.be/hTWeP--xQCg",
  "https://youtu.be/FElxTVLXeHw",
  "https://www.youtube.com/watch?v=V0JjhJVJbEM",
  "https://youtu.be/DxGYtOZ35-Y?t=16",
  "https://youtu.be/_tPxHvP6ArQ",
  "https://www.youtube.com/watch?v=iUQaxSE_dGg",
  "https://www.youtube.com/watch?v=i6UPykaA9GM&feature=youtu.be",
  "https://youtu.be/agg1ghcqpJo",
  "https://www.youtube.com/watch?v=dCAORZphnlY",
  "https://www.youtube.com/watch?v=6-vargLeWoU",
  "https://www.youtube.com/watch?v=iv5wXEwDiaw",
  "https://www.youtube.com/watch?v=Aj8vIgSRahI",
  "https://www.youtube.com/watch?v=U86Lanwyu-Q",
  "http://youtu.be/DPKQwfIe5YQ",
  "https://www.youtube.com/watch?v=kWQQT_1H1hw",
  "https://www.youtube.com/watch?v=AQhSfEQqqKU",
  "https://www.youtube.com/watch?v=xvsGxZY4mXM",
  "https://youtu.be/2cPynkqjfuw",
  "https://www.youtube.com/watch?v=rh1YsDtzj_I",
  "https://www.youtube.com/watch?v=_lUUZQWZlEA",
  "https://youtu.be/nYwMZmL6SYw",
  "https://youtu.be/-nD9FsSdLDo",
  "https://youtu.be/7DRL6ocEK-M",
  "https://youtu.be/bLa6IpTgdv0",
  "https://www.youtube.com/watch?v=W34lWyZT1MA",
  "https://youtu.be/SEIoYyAoXNg",
  "https://youtu.be/FvQjh_bS-Ig",
  "https://youtu.be/t1r6BmNJX6Y",
  "http://youtu.be/QkqnY0MeNp0",
  "https://youtu.be/i63cgUeSsY0",
  "https://www.youtube.com/watch?v=l-YsSL65quM",
  "https://www.youtube.com/watch?v=lN-rVlMIJZs",
  "https://www.youtube.com/watch?v=8Jgj9ZDs4-8",
  "https://www.youtube.com/watch?v=BadxZotBjv8&feature=youtu.be",
  "https://youtu.be/hu3bfOl91RQ#t=9s",
  "https://www.youtube.com/watch?v=AkHwUMKYJ3I",
  "https://www.youtube.com/watch?v=yo_CWdFdYrQ",
  "https://youtu.be/ihvrbKlNDGM",
  "https://youtu.be/5XHsDJ-9EDs",
  "https://www.youtube.com/watch?v=HyJSbbJXLjc",
  "https://www.youtube.com/watch?v=wphawoHiUUg&feature=youtu.be&t=25s",
  "https://www.youtube.com/watch?v=5EISAL7aa0A",
  "https://www.youtube.com/watch?v=PrbIzJRg3xk&feature=youtu.be&t=30",
  "https://youtu.be/kMA0cmB_eoU",
  "https://www.youtube.com/watch?v=GqUnKcXKN5Y",
  "https://www.youtube.com/watch?v=QQ9gs-5lRKc",
  "https://www.youtube.com/watch?v=4ROUoSgeYio",
  "https://www.youtube.com/watch?v=mtQVPPpmfYU&feature=youtu.be",
  "https://www.youtube.com/watch?v=nSH0hCFHoZQ",
  "https://www.youtube.com/watch?v=z8h1lvNEq-I",
  "https://www.youtube.com/watch?v=zwanzcpWKKY",
  "https://www.youtube.com/watch?v=VRyva6PQwEQ",
  "https://www.youtube.com/watch?v=m4lA0brtsik&",
  "https://www.youtube.com/watch?v=d3pQ0oO_cDE",
  "https://www.youtube.com/watch?v=UujtfN7hCdo",
  "https://www.youtube.com/watch?v=BGdHRU1jDQo",
  "https://www.youtube.com/watch?v=JBTxl9H03Q8",
  "https://www.youtube.com/watch?v=1tLyn1R0eR8",
  "https://www.youtube.com/watch?v=5S3iTRd3mx0",
  "https://www.youtube.com/watch?v=kMI_HBO5FOM",
  "https://www.youtube.com/watch?v=X7luPEFT-6k",
  "https://www.youtube.com/watch?v=9O2YGo0pqME",
  "https://youtu.be/9MNIVXd7TKY?t=38s",
  "https://www.youtube.com/watch?v=OgkeLALdEvg",
  "https://www.youtube.com/watch?v=mnLuw0-WguQ",
  "https://youtu.be/yNz3fNOvpfM",
  "https://www.youtube.com/watch?v=R88cOg1qRiQ",
  "https://www.youtube.com/watch?v=wT5JSdjnaic&feature=youtu.be",
  "http://www.youtube.com/watch?v=I4sTnDJolyM&sns=em",
  "https://youtu.be/sgUlmObW6pM",
  "https://youtu.be/VcYq1RthWFo",
  "https://www.youtube.com/watch?v=nC8j3bJJsj8&feature=youtu.be&t=2s",
  "https://www.youtube.com/watch?v=iAudpnpWxjA",
  "https://www.youtube.com/watch?v=CH5c-FRzXGs",
  "https://www.youtube.com/watch?v=_-qZv1IsEpQ",
  "https://www.youtube.com/watch?v=FiIfOjZu16I",
  "https://www.youtube.com/watch?v=iwZUXiVp8cg",
  "https://www.youtube.com/watch?v=U1eYdluUfg4",
  "https://www.youtube.com/watch?v=7roY6TEQ8CY&feature=youtu.be",
  "https://www.youtube.com/watch?v=NBdY7qn_nok",
  "https://www.youtube.com/results?search_query=finger+nursery+rhymes",
  "https://www.youtube.com/watch?v=aQVP4nyB8n0",
  "https://youtu.be/phODcH8Kflg?t=19",
  "https://www.youtube.com/watch?v=POcuiqRTbnM",
  "https://www.youtube.com/watch?v=rHVYM78pUaE&feature=youtu.be",
  "https://www.youtube.com/watch?v=U-vwXpIvVMo",
  "https://www.youtube.com/watch?v=cUZlP6LJBA4",
  "https://www.youtube.com/watch?v=JC-5AXzSq3E",
  "https://www.youtube.com/watch?v=AXcobiK_5fA",
  "https://www.youtube.com/watch?v=C9oVVBKiWnY",
  "https://www.youtube.com/watch?v=ctzorEI4msk",
  "https://youtu.be/gADoZu2Ld_s?t=6",
  "https://youtu.be/emzZIOxPbm4",
  "https://youtu.be/8TyrwwQLuRI",
  "https://www.youtube.com/watch?v=kmOCIyia_S4",
  "https://www.youtube.com/watch?v=KUqhrj1sKl4",
  "https://www.youtube.com/watch?v=9DCCjczeM9U",
  "https://www.youtube.com/watch?v=g4ZPF1PfALw",
  "https://m.youtube.com/watch?v=FiS6ojAv7xk",
  "https://www.youtube.com/watch?annotation_id=annotation_778292543&feature=iv&list=PL4D7AAF479B399727&src_vid=ZbM6WbUw7Bs&v=5dbG4wqN0rQ",
  "http://youtu.be/ZW1JynXgpQs",
  "https://youtu.be/JZCQ2vFGEH8",
  "https://www.youtube.com/watch?v=82tna-pc_0M",
  "https://www.youtube.com/watch?v=_PHS3mZb8qY",
  "https://www.youtube.com/watch?v=GAeFqg6bAE8",
  "https://www.youtube.com/watch?v=KmmY7dcaEfY",
  "https://youtu.be/VsH6zCL2RRo",
  "https://youtu.be/vpGFjnCEy0w",
  "https://www.youtube.com/watch?v=c9nn2XFzcEs",
  "https://www.youtube.com/watch?v=Oyr-rVQqa0A",
  "https://www.youtube.com/watch?v=lSF2osgAsqQ",
  "https://www.youtube.com/watch?v=FueroIV7Mvk",
  "https://youtu.be/AnbNLnBFJz8",
  "https://www.youtube.com/watch?v=LFFvJaMhgDg",
  "https://www.youtube.com/watch?v=pxF11lVG9xQ",
  "https://youtu.be/8oPpYf-YopQ",
  "https://www.youtube.com/watch?v=DPaprgK_teA",
  "https://www.youtube.com/watch?v=M0IIPsCHbZ8",
  "https://www.youtube.com/watch?v=ApcFx1aXFqk",
  "https://www.youtube.com/watch?v=YDqNgRbb-z4&t=1s",
  "https://www.youtube.com/watch?v=4OZgN7lqNvo&feature=youtu.be",
  "https://www.youtube.com/watch?v=MVPW-nF5aXQ",
  "https://www.youtube.com/watch?v=tuluUJK9tHY",
  "https://www.youtube.com/watch?v=XHiW2HkVFV0&ab_channel=SheaMarshall",
  "https://www.youtube.com/watch?v=sKfUUvn7lBM",
  "https://www.youtube.com/watch?v=58mlWYFeQ_4",
  "https://youtu.be/8C-jIbu_YII",
  "https://www.youtube.com/watch?v=V5uHdQARB-o",
  "https://www.youtube.com/watch?v=lEmgSetYK2M",
  "https://www.youtube.com/watch?v=p6Jvcj8XvZw&feature=youtu.be",
  "https://www.youtube.com/watch?v=I0tE6T-ecmg",
  "https://www.youtube.com/watch?v=XIf7wW8s9II",
  "https://www.youtube.com/watch?v=ZBKaPgkYlvc",
  "https://www.youtube.com/watch?v=A8MwM_gD2sQ&feature=youtu.be",
  "https://youtu.be/sojpBtBV53w?t=11s",
  "https://youtu.be/88sKvnB5yZM",
  "https://youtu.be/kNrxUVe4T1U",
  "https://youtu.be/Cy8_gFPzS7U",
  "https://youtu.be/5s0cWGrZxnU?t=20",
  "https://www.youtube.com/watch?v=tOOTYsZiHjU",
  "https://www.youtube.com/watch?v=u5yXB1Tq2j0",
  "https://www.youtube.com/watch?v=vBYvm6L2hA0&feature=youtu.be",
  "https://www.youtube.com/watch?v=2B49SrGVkf8&feature=youtu.be",
  "https://www.youtube.com/watch?v=xrtn1jrCCkw",
  "https://www.youtube.com/playlist?list=PL0SUh6QqUUxVUWl3DLjVszMEtrVF94ifC",
  "https://www.youtube.com/watch?v=tBGAP4pn8ys",
  "https://www.youtube.com/watch?v=6BtuqUX934U",
  "https://www.youtube.com/watch?v=1-pkRmBon4k",
  "https://youtu.be/1Hf8a-qSkss",
  "https://www.youtube.com/watch?v=b9EkMc79ZSU",
  "https://youtu.be/oXuc3M_3cs8",
  "https://www.youtube.com/watch?v=IeR58PwrO3A",
  "https://www.youtube.com/watch?v=yRFJnc3WC7g",
  "https://www.youtube.com/watch?v=McQde-teTaE",
  "https://www.youtube.com/watch?v=LmmaHMecM1E&ab_channel=kmlkmljkl",
  "https://www.youtube.com/watch?v=sqn_z9PDUwM",
  "https://www.youtube.com/watch?v=8b8NraycwjY&feature=youtu.be",
  "http://youtu.be/yQRUz9MTxiA",
  "https://www.youtube.com/watch?v=SKgmeGkn-Iw",
  "https://youtu.be/xnIvpqX4nD8",
  "https://www.youtube.com/watch?v=NYtQXtUMWvQ&feature=youtu.be",
  "https://www.youtube.com/watch?v=P6EZubn2S_s&feature=youtu.be",
  "https://www.youtube.com/watch?v=8WqtFv3x7c4&feature=youtu.be",
  "https://youtu.be/AHqhrcvx8zQ",
  "https://www.youtube.com/watch?v=gzwlhHdhOcc",
  "https://www.youtube.com/watch?v=YH7aigRGBSU",
  "https://www.youtube.com/watch?v=vxFUN9p7LRo",
  "https://www.youtube.com/watch?v=hitTrwczcP8",
  "https://youtu.be/ww2e51X_2kw",
  "https://www.youtube.com/watch?v=wdVZ5a7jHio",
  "https://www.youtube.com/watch?v=rLUjjin4g5k",
  "https://www.youtube.com/watch?v=931YsdJIaH4",
  "https://www.youtube.com/watch?v=7d9-RxHRtR0",
  "https://www.youtube.com/watch?v=-UhHcEiegb8",
  "https://www.youtube.com/watch?v=LvZKifHc7ao",
  "https://www.youtube.com/watch?v=pBXpPRLZlDw",
  "https://www.youtube.com/watch?v=nGaKl31fXFw&feature=youtu.be",
  "https://youtu.be/pPKWLeoX-ok?t=12s",
  "https://youtu.be/HZeXph2WP98",
  "https://www.youtube.com/watch?v=hhtOHeAmCbs",
  "https://www.youtube.com/watch?v=GPdH1DhIV04",
  "https://www.youtube.com/watch?v=kBfv5slBXLE",
  "https://www.youtube.com/watch?v=rM4ILAlrod4",
  "https://www.youtube.com/watch?v=8CfLt2jwZZs",
  "https://www.youtube.com/watch?v=SP1IugTsrhc&feature=youtu.be&t=2s",
  "https://www.youtube.com/watch?v=Ql_gWZsftOM",
  "https://youtu.be/BOip_QWnFzY",
  "https://www.youtube.com/watch?v=hkvy00x3Dt0",
  "https://www.youtube.com/watch?v=3kogE9tnnd0",
  "https://www.youtube.com/watch?v=JN53X7Zwu90",
  "https://www.youtube.com/watch?v=-7PUIIvtSlU",
  "https://youtu.be/0CLrs5xepPo",
  "https://www.youtube.com/watch?v=iOcZfEgWCAY",
  "https://www.youtube.com/watch?v=-fWP1pTypLo",
  "https://www.youtube.com/watch?v=wUYC9SlrnZc",
  "https://www.youtube.com/watch?v=pBXpPRLZlDw&feature=youtu.be&t=5s",
  "https://www.youtube.com/watch?v=EBMazjVZ3VI",
  "https://youtu.be/G2MdDPkkyMY",
  "https://www.youtube.com/watch?v=VvZDdcobs8w",
  "https://www.youtube.com/watch?v=cd4-UnU8lWY",
  "https://m.youtube.com/watch?v=RCKDeIWKXUE",
  "https://www.youtube.com/watch?v=7G-42TjhsVQ",
  "https://youtu.be/ft3A5L9QORw",
  "https://www.youtube.com/watch?v=sKlKgfdNrpo",
  "https://www.youtube.com/watch?v=gzUeqjd0YnY&feature=youtu.be&t=2",
  "https://www.youtube.com/watch?v=A5jJB2iEZC0&feature=youtu.be",
  "https://youtu.be/NtTFCn-7S9U?t=23s",
  "https://youtu.be/l4edjt1PYxE",
  "https://www.youtube.com/watch?v=FAYfrID8LAA",
  "https://youtu.be/uGjv7YgXgN8",
  "https://youtu.be/sCNJIgM5jlk",
  "https://youtu.be/6VuhvIHhXo0",
  "https://www.youtube.com/watch?v=kPv50h56GAQ",
  "https://youtu.be/WtzePhvCdBg",
  "https://youtu.be/2htQuZefoaA",
  "https://www.youtube.com/watch?v=gvM4aatXz1I",
  "https://www.youtube.com/watch?v=r7IJ8QJITGI&feature=youtu.be",
  "https://www.youtube.com/watch?v=nyefc-KuJq8&feature=youtu.be",
  "https://www.youtube.com/watch?v=m5qH4oUn-yM",
  "https://www.youtube.com/watch?v=7F-6-lYxoSo",
  "https://youtu.be/Zwej6LQSl58",
  "https://www.youtube.com/watch?v=N8EHhiI0cWg",
  "https://www.youtube.com/watch?v=gb8nN4PIrYs&ab_channel=TotalBiscuit",
  "https://www.youtube.com/watch?v=CtpZrcypMUw",
  "https://www.youtube.com/watch?v=4o09yTSVMtw",
  "https://www.youtube.com/watch?v=4QrQ7QeZLdA",
  "https://www.youtube.com/watch?v=i2rLG6uZik0&feature=youtu.be",
  "https://www.youtube.com/watch?v=Yu8n5YrcYv4",
  "http://youtu.be/B2MI4Uuxa3s",
  "https://www.youtube.com/watch?v=PuvCPqLR9pg",
  "https://www.youtube.com/watch?v=_0soBMLNqaU&feature=youtu.be",
  "https://youtu.be/UXHwIrEpeSQ?t=49",
  "https://www.youtube.com/watch?v=33UVnIOj-ZA&feature=youtu.be",
  "https://www.youtube.com/watch?v=QylKyJrDnTM&feature=youtu.be&t=2m48s",
  "https://www.youtube.com/watch?v=wA98V8nyagA",
  "https://www.youtube.com/watch?v=UUc5ieq-op0&feature=youtu.be",
  "https://www.youtube.com/watch?v=RvAwB7ogkik",
  "https://www.youtube.com/watch?v=4pgVh7dD1M8&feature=youtu.be",
  "https://www.youtube.com/watch?v=0MKjKDcIBXU",
  "https://www.youtube.com/watch?v=OBqt_jdOTqM",
  "https://www.youtube.com/watch?v=SGmvv71RTfU",
  "https://www.youtube.com/watch?v=FkOHdTQDleY",
  "https://www.youtube.com/watch?v=JlK_EO87bRY&feature=youtu.be",
  "https://www.youtube.com/watch?v=RR_HPo_7ZEE&feature=youtu.be",
  "https://www.youtube.com/watch?v=xCBNyC3eIm0",
  "http://www.youtube.com/watch?v=AGu1EoyYcto",
  "https://www.youtube.com/watch?v=x4eUpT0BBxg",
  "https://www.youtube.com/watch?v=aWAhRU8y8uc",
  "https://www.youtube.com/watch?v=EW0_EJfTXME",
  "https://www.youtube.com/watch?v=_-1tXiXlFPA&feature=youtu.be",
  "https://youtu.be/a5qBQBMgpOg?t=5s",
  "https://www.youtube.com/watch?v=L6i80-WPSw8",
  "https://youtu.be/4pgVh7dD1M8",
  "https://youtu.be/zzb51JKBYng",
  "https://www.youtube.com/watch?v=4TtChMM14J4",
  "https://www.youtube.com/watch?v=5QkBZJTEFYU",
  "https://www.youtube.com/watch?v=aVurAgoiZ0Y",
  "https://www.youtube.com/watch?v=lGsEGNSij0k",
  "https://youtu.be/fGJETmOGLcI",
  "https://www.youtube.com/watch?v=Kcj_1LyaDTQ&t=0m31s",
  "https://youtu.be/YLQafsS8Bgg",
  "https://www.youtube.com/watch?v=JvdEo22J7o8",
  "https://www.youtube.com/watch?v=g5iGMS1Wja4",
  "https://www.youtube.com/watch?v=eba_ed1ClWU",
  "https://youtu.be/tDJE-ZipSFs",
  "https://www.youtube.com/watch?v=h232bilq8eQ",
  "https://www.youtube.com/watch?v=hJIkJ9x0-JQ",
  "https://www.youtube.com/watch?v=E3qI1UWZlBo",
  "https://www.youtube.com/watch?v=lFSWFjxOy64",
  "https://www.youtube.com/watch?v=coCNTFSjd_M",
  "https://www.youtube.com/watch?v=SarwDUTas9M",
  "https://www.youtube.com/watch?v=RRBQWYtlILY",
  "https://youtu.be/V1q2_TrB3NA",
  "https://www.youtube.com/watch?v=-Yn4UOpMARw",
  "https://www.youtube.com/watch?v=eG9bWl0Z5pc",
  "https://www.youtube.com/watch?v=ODvGkgMILys",
  "https://www.youtube.com/watch?v=gAJnAJVrMgU",
  "https://www.youtube.com/watch?v=tl2gc7qgBdY",
  "https://www.youtube.com/watch?v=OwGnfgA0G9k",
  "https://www.youtube.com/watch?v=bLlj_GeKniA",
  "https://www.youtube.com/watch?v=yet2Q0M32GQ",
  "https://www.youtube.com/watch?v=BLTy6AfWqac",
  "https://www.youtube.com/watch?v=RHgmVcsx0hU",
  "https://www.youtube.com/watch?v=bfP4U-tvql8",
  "https://www.youtube.com/watch?v=mEHqLZPDyDY&feature=youtu.be",
  "https://www.youtube.com/watch?v=_pBiG9rkPTA",
  "https://www.youtube.com/watch?v=Zh6KCzhIVlY",
  "https://www.youtube.com/watch?v=77tSEBQeLDI",
  "https://www.youtube.com/watch?v=xY7m4KzYR4Q",
  "https://www.youtube.com/watch?v=VXNj2BobjJ4",
  "https://www.youtube.com/watch?v=r5YQvmBdfR4",
  "https://youtu.be/xjst9kmTv-4",
  "https://youtu.be/mX-OkFWpNBM",
  "https://www.youtube.com/watch?v=vULshlDlhWE",
  "https://www.youtube.com/watch?v=I4sTnDJolyM&sns=em",
  "https://www.youtube.com/watch?v=1aNDKb37I18",
  "https://youtu.be/2dn61ZBTxVU",
  "https://www.youtube.com/watch?v=bNXqe1N5bxA",
  "https://youtu.be/S9VG2ZL7CS4",
  "https://www.youtube.com/watch?v=pV-Zi2nxNIE",
  "https://www.youtube.com/watch?v=9PCqNuTbQnQ",
  "https://youtu.be/k8SKEfjJcYA",
  "https://www.youtube.com/watch?v=YZWWgR9iwJI",
  "https://youtu.be/JEIUwjLyPVI?t=4",
  "https://youtube.com/watch?v=sQ8FKMkwpP0&feature=youtu.be",
  "https://www.youtube.com/watch?v=ywdxu13HSUQ",
  "https://youtu.be/ElMPJJsP8z0",
  "https://www.youtube.com/watch?v=tm_seikE8A8",
  "https://youtu.be/piKt0qYxAWs",
  "https://www.youtube.com/watch?v=x0AD3p3dO2Q&feature=youtu.be",
  "https://www.youtube.com/watch?v=CFPA0QN1eOo",
  "https://www.youtube.com/watch?v=kt-4VLyzZUY",
  "https://youtu.be/MR-6Gbbph04",
  "https://youtu.be/lnQ0EFaNdHA",
  "https://www.youtube.com/watch?v=G-a_75PJJ5M",
  "https://www.youtube.com/watch?v=FP_n_F-OFio",
  "https://www.youtube.com/watch?v=ERuWlMWO92E&feature=youtu.be",
  "https://www.youtube.com/watch?v=KUejf-s7Vjo&feature=youtu.be&t=1m9s&ab_channel=LikeIDKWhatever",
  "https://www.youtube.com/watch?v=VQS4uUtm2kw",
  "https://www.youtube.com/watch?v=_QDYjCRODKw",
  "https://www.youtube.com/watch?v=0lJVj7rUq2k",
  "https://youtu.be/a_igJhf7Eno",
  "https://www.youtube.com/watch?v=imgtfKJih1Y&feature=youtu.be",
  "https://www.youtube.com/watch?v=eH10efX7Png",
  "https://youtu.be/ORnx1iTo2MQ",
  "https://youtu.be/6BsoD_w2fys",
  "https://www.youtube.com/watch?v=TJENJe69JTM&feature=youtu.be",
  "https://youtu.be/RxS-U3odMxI",
  "https://www.youtube.com/watch?v=p-IvCJ6AuJU&feature=youtu.be",
  "https://youtu.be/iJIccisGrIE",
  "https://www.youtube.com/watch?v=iR3K9iGkzTA",
  "https://www.youtube.com/watch?v=oeUdGSoAMhs",
  "https://youtu.be/T9MGG388woQ?t=9s",
  "https://www.youtube.com/watch?v=69acgdtteBQ",
  "https://youtu.be/SdUxctoVjnc",
  "https://www.youtube.com/watch?v=eg15Xd0eePE",
  "https://www.youtube.com/watch?v=BVw9XnGghOg",
  "https://www.youtube.com/watch?v=nWclO-GwMjU&feature=youtu.be",
  "https://www.youtube.com/watch?v=OLpeX4RRo28",
  "https://youtu.be/hR0obigza9c?t=70",
  "https://youtu.be/lU7rZAdUJQY",
  "https://www.youtube.com/watch?v=Dhsl2Jvk9PI",
  "https://www.youtube.com/watch?v=PHmjepxIeAg",
  "https://www.youtube.com/watch?v=je2QHFP9KPo",
  "https://www.youtube.com/watch?v=mzScos3-1Tc&feature=youtu.be",
  "https://www.youtube.com/watch?v=CPASfMsAeSI&feature=youtu.be",
  "https://www.youtube.com/watch?v=9UIXZjhSS5U",
  "https://youtu.be/WoMKEV9op-c",
  "https://youtu.be/8YWl7tDGUPA?t=2s",
  "https://www.youtube.com/watch?v=rjr-8OeKWoI",
  "https://www.youtube.com/watch?v=o4vGDYxKjqM",
  "https://m.youtube.com/watch?v=8UpeLPBKi1I",
  "https://www.youtube.com/watch?v=uXzSOlSYhck",
  "https://www.youtube.com/watch?v=VMdYPQa6Ys0",
  "https://www.youtube.com/watch?v=dU-6iT1jBi8",
  "https://www.youtube.com/watch?v=L8G1C7dPmt8.",
  "https://www.youtube.com/watch?v=QuaCCKr3yQY&list=PL0QrZvg7QIgpoLdNFnEePRrU-YJfr9Be7&index=114",
  "https://www.youtube.com/watch?v=4iQch1KxFN0",
  "https://www.youtube.com/watch?v=PrHJIZDIJfg",
  "https://www.youtube.com/watch?v=YF-UgYAfUVE",
  "https://youtu.be/8o6SeDeGnSQ",
  "https://youtu.be/qutYwlsIL7s?t=343",
  "https://youtu.be/9UZTh1aJ5rs",
  "https://www.youtube.com/watch?v=u9Lh2CivJE8",
  "https://youtu.be/KpljBt1Qkpc",
  "https://www.youtube.com/watch?v=g-zV6nuk6kk",
  "https://www.youtube.com/watch?v=D2brukbh378",
  "https://www.youtube.com/watch?v=5lL5xRKquPQ&feature=youtu.be",
  "https://www.youtube.com/watch?v=fx8vkbtjSNI",
  "https://www.youtube.com/watch?v=VYQ2o1-hZTI",
  "https://www.youtube.com/watch?v=xKvP8jgsINs&feature=youtu.be",
  "https://www.youtube.com/watch?v=QX1SojKfgNI",
  "https://www.youtube.com/watch?v=1ZShMPu7BqA",
  "https://www.youtube.com/watch?v=fWNv52S4leE",
  "https://www.youtube.com/watch?v=qmfqpOGzOhI",
  "https://www.youtube.com/watch?v=Se4jBlvtA7M",
  "https://youtu.be/wgX4azFYqsM",
  "https://www.youtube.com/watch?v=RXcnfThSe9s",
  "https://youtu.be/hFw76OR9IDY",
  "https://www.youtube.com/watch?v=HTvzkVtISOg&feature=share",
  "https://www.youtube.com/watch?v=wbmMLGiwmE4",
  "https://youtu.be/xN0vUlljX0I?t=2m11s",
  "https://www.youtube.com/watch?v=p8ccLPa3JTU&t=2s",
  "https://www.youtube.com/watch?v=plwX5I4_t8Y&feature=youtu.be",
  "https://www.youtube.com/watch?v=ukB8jDOAUfk&feature=youtu.be",
  "https://www.youtube.com/watch?v=5tnAIeaasL0",
  "https://www.youtube.com/watch?v=dEBVG7fXNLE",
  "https://www.youtube.com/watch?v=r15zkxve5Fc",
  "https://youtu.be/-gLps600_xA",
  "https://www.youtube.com/watch?v=wpV7h5pHla8",
  "https://youtu.be/VhFDmEx0Rvk?t=14s",
  "https://www.youtube.com/watch?v=jVvt7hP5a-0",
  "https://www.youtube.com/watch?v=LE_9YhaAa3M&feature=youtu.be",
  "https://www.youtube.com/watch?v=oYnZkmJ1Ymo",
  "https://www.youtube.com/watch?v=WwrZFIo61o0",
  "https://www.youtube.com/watch?v=-M4G4ZjYM2U",
  "https://www.youtube.com/watch?v=y8cqmFKFVMM",
  "https://www.youtube.com/watch?v=9Cn5R3rvbHA",
  "https://youtu.be/c_xWh2xzhxg",
  "https://www.youtube.com/watch?v=SO1NvBd72L4",
  "https://www.youtube.com/watch?v=OjO_bEoTwnY",
  "https://youtu.be/XOjkiCnG8R4?t=2s",
  "https://www.youtube.com/watch?v=4QC8-DOvciw",
  "https://www.youtube.com/watch?v=yFyV00hxKdA",
  "https://youtu.be/tvPW3ZP12kI",
  "https://www.youtube.com/watch?v=Oey4pS_Vh5o",
  "https://youtu.be/iRVAznX3SII",
  "https://www.youtube.com/watch?v=6OUxH49J5tI",
  "https://youtu.be/EYFTBIbnraE",
  "https://www.youtube.com/watch?v=m2t5meAN57c",
  "https://www.youtube.com/watch?v=PwgzxR1E-go",
  "https://youtu.be/EDBE7p8nO4A?t=18s",
  "https://www.youtube.com/watch?v=I0pWl0st8rY&feature=youtu.be",
  "https://www.youtube.com/watch?v=nHsCip_JEDw",
  "https://www.youtube.com/watch?v=lfpqsHrRwBc",
  "https://youtu.be/IwYBEeHUmzM",
  "https://www.youtube.com/watch?v=tdAUWQaqDx8",
  "https://www.youtube.com/watch?v=xshkpERaVZk",
  "https://youtu.be/mv__SDGIrIU",
  "https://www.youtube.com/watch?v=9S7TcdkUB0w",
  "https://youtu.be/jWv6F3uWBzA",
  "https://youtu.be/X0zzP1Zeiak",
  "https://www.youtube.com/watch?v=DNyMMntgvIo",
  "https://youtu.be/UsaGiyhjxCw",
  "https://www.youtube.com/watch?v=r_VShVC7DLk&t=0s",
  "https://www.youtube.com/watch?v=Ok51S7IhWk0",
  "https://www.youtube.com/watch?v=JKy-1X-dkec",
  "https://www.youtube.com/watch?v=0rxAKKtnhrc",
  "https://www.youtube.com/watch?v=0vIY9X6LQfQ",
  "https://m.youtube.com/watch?feature=youtu.be&v=tUOrwWge62A",
  "https://youtu.be/Tb5Ty-YFgxU",
  "https://www.youtube.com/watch?v=aIA0mRCj14I",
  "https://youtu.be/v3tMIfRTPEI",
  "https://www.youtube.com/watch?v=5yf1zZFHrvU",
  "https://www.youtube.com/watch?v=bfAxmGfbZmI",
  "https://www.youtube.com/watch?v=VZC6lcqNyJM",
  "https://youtu.be/eqArLUr06ZE",
  "https://www.youtube.com/watch?v=ZK_ACuGziPQ&feature=youtu.be",
  "https://www.youtube.com/watch?v=Aiu-RT1OisQ",
  "https://www.youtube.com/watch?v=7QifJonMsYc&feature=youtu.be",
  "https://youtu.be/ozHjt3qiLYo",
  "https://www.youtube.com/watch?v=TZLgyu55Wgc",
  "https://www.youtube.com/watch?v=qaoSEVhh4bM",
  "https://www.youtube.com/watch?v=-1pISqXO2Jc",
  "https://youtu.be/EY2kBBXi0XY",
  "https://www.youtube.com/watch?v=DHdAz3N70CY",
  "https://youtu.be/vPPuVek6vWU",
  "https://www.youtube.com/watch?v=pDcqfEPhLQg",
  "https://www.youtube.com/watch?v=pR7hQrCpqxc&feature=youtu.be",
  "https://www.youtube.com/watch?v=3l8QuDNTqR4",
  "https://youtu.be/uMGBvcGBZL8?t=1s",
  "https://www.youtube.com/watch?v=W04TObp5Jgs&feature=youtu.be&ab_channel=CaptainAlliance",
  "https://www.youtube.com/watch?v=u8FFYBC1FBg",
  "https://www.youtube.com/watch?v=LD7VPW781vQ&feature=youtu.be",
  "https://youtu.be/kbyQultvtNI",
  "https://www.youtube.com/watch?v=geU5GLluUtk",
  "https://youtu.be/1hYImf24EgQ",
  "https://youtu.be/hK0et2xzHD8",
  "https://youtu.be/LN3NFYXcBjg",
  "https://www.youtube.com/watch?v=U-1Uwryemto",
  "https://www.youtube.com/watch?v=uejcn7HO0rg&feature=youtu.be",
  "https://www.youtube.com/watch?v=UW-MpiKmS8Q",
  "https://youtu.be/4egk-AbRM5o",
  "https://www.youtube.com/watch?v=5CXbdeOQP24",
  "https://www.youtube.com/watch?v=ess9bRJ0bPw",
  "https://youtu.be/yytbDZrw1jc",
  "https://youtu.be/Yvu5DtPK2iI",
  "https://www.youtube.com/watch?v=6ibrkv1ot0c",
  "https://www.youtube.com/watch?v=e3MMCVymBR0",
  "https://youtu.be/bobZ3TL0hQc",
  "https://youtu.be/FFJfdzT8MOc",
  "https://www.youtube.com/watch?v=0oTqaWdZ-Bo",
  "https://www.youtube.com/embed/LMyxCno8NWs?start=785&end=800&vq=hd1080",
  "https://www.youtube.com/watch?v=T7fosyMHpco",
  "https://youtu.be/UkRoCE8J0Mc",
  "https://youtu.be/cpDFVFax5vE",
  "https://www.youtube.com/watch?v=HWBCsWO_S8I",
  "https://youtu.be/fMNMw72_1fk",
  "https://www.youtube.com/watch?v=xKvP8jgsINs",
  "https://www.youtube.com/watch?v=duT3zPGUSkk.",
  "https://www.youtube.com/watch?v=duT3zPGUSkk",
  "https://www.youtube.com/watch?v=IfMm-aMB5w0",
  "https://www.youtube.com/watch?v=yIvHPyiatEs",
  "https://www.youtube.com/watch?v=feQR5Bf4ZJI",
  "https://www.youtube.com/watch?v=4Hk8nuYKFQQ",
  "https://www.youtube.com/watch?v=ipeUSXDU6HI",
  "https://youtu.be/1MHYVMkbbLg",
  "https://youtu.be/iuvYXjnEirc?t=5s",
  "https://m.youtube.com/watch?v=H2psPzJXws0&feature=youtu.be",
  "https://www.youtube.com/watch?v=Ztev5n7n6Jg",
  "https://youtu.be/0MGhovUxCaM",
  "https://www.youtube.com/watch?v=ipliF4lsviA",
  "https://youtu.be/fPEhhS26vb0",
  "https://www.youtube.com/watch?v=W37ER1p1tiI&feature=youtu.be",
  "https://youtu.be/Q-zhzwBkAUE",
  "https://www.youtube.com/watch?v=kv2F46zTSyw",
  "https://youtu.be/JLCqVo_2TE8?t=7",
  "https://www.youtube.com/watch?v=Qh3Y2Y8uz-4",
  "https://www.youtube.com/watch?v=UzzD9s07y2s&feature=youtu.be",
  "https://www.youtube.com/watch?v=Ia-BLOKT1xE",
  "https://www.youtube.com/watch?v=mksKTqeJzMQ",
  "https://www.youtube.com/watch?v=2ouZ17FD54I",
  "https://www.youtube.com/watch?v=r9odPEVtY14&feature=youtu.be",
  "https://youtu.be/Bo60NNctNio",
  "https://youtu.be/aHVIC7IHEOc",
  "https://www.youtube.com/watch?v=HbMCqx1N0yA&feature=youtu.be",
  "https://youtu.be/XKaFjCUH5DM?t=10s",
  "https://www.youtube.com/watch?v=xMTQBoXH9OU",
  "https://www.youtube.com/watch?v=eKRw0W6UVCQ",
  "https://www.youtube.com/watch?v=-8AIHLQ81xs",
  "https://www.youtube.com/watch?v=afIFzAo384k&app=desktop",
  "https://www.youtube.com/watch?v=hFs6YxWWu_4",
  "https://www.youtube.com/watch?v=WmXJgcY9_lQ",
  "https://www.youtube.com/watch?v=uiYINPqz7ow",
  "https://youtu.be/B35YAcdkmVI",
  "https://www.youtube.com/watch?v=tPoEaSaGH5Q&feature=youtu.be",
  "https://www.youtube.com/watch?v=hovhnjlmRYU&feature=youtu.be",
  "https://www.youtube.com/watch?v=tDEjAX466gk",
  "https://youtu.be/Hf--yOCqWnw",
  "https://youtu.be/NRMpNA86e8Q?t=2m52s",
  "https://www.youtube.com/watch?v=5VgBJUUGPM4",
  "https://youtu.be/MKWYj5vRuEA",
  "https://www.youtube.com/watch?v=Ac9LaOWryOU",
  "https://www.youtube.com/watch?v=GU9OM-CikrU",
  "https://www.youtube.com/watch?v=zCTEx8kHtqQ",
  "https://www.youtube.com/watch?v=7VEM2eExtp8",
  "https://www.youtube.com/watch?v=nj12LaDvwKA",
  "https://youtu.be/__3iCy8h5pM",
  "https://www.youtube.com/watch?v=vBJbMKj9UNw&t=2s",
  "https://www.youtube.com/watch?v=Pz7q5XiW87o",
  "https://youtu.be/LqwFPuOCDPo",
  "https://www.youtube.com/watch?v=zP3b38pM3Yo",
  "https://www.youtube.com/watch?v=81sOGsfbEDo",
  "https://www.youtube.com/watch?v=E_iM5R4Xi8I",
  "https://www.youtube.com/embed/azz8IRBF1wU?start=4&end=34",
  "https://www.youtube.com/watch?v=6A9M226_28Y",
  "https://www.youtube.com/watch?v=CQ_Fbs5GpCQ",
  "https://www.youtube.com/watch?v=xKGdtotkoPw",
  "https://www.youtube.com/watch?v=22kHNWK1eLk",
  "https://youtu.be/TGy5iATtajs",
  "https://www.youtube.com/watch?v=XbPlJUpAw9Y",
  "https://www.youtube.com/watch?v=91LnAZZblzg",
  "https://www.youtube.com/watch?v=OCz60K0at2U",
  "https://www.youtube.com/watch?v=shxsDkRQnTE&feature=youtu.be",
  "https://www.youtube.com/watch?v=uvF3SEKQmTo",
  "https://youtu.be/95DAXZ79OA4",
  "https://www.youtube.com/watch?v=nCEj_yEiMeg",
  "https://www.youtube.com/watch?v=d_CaZ4EAexQ",
  "https://www.youtube.com/watch?v=LxMdc6c7KfM",
  "https://www.youtube.com/watch?v=KmQbth4R8h4",
  "https://www.youtube.com/watch?v=PhtIIhjRh4E",
  "https://youtu.be/weayWWQuhmE",
  "https://www.youtube.com/watch?v=xPYD5B0LPks",
  "https://youtu.be/tDwUB0QBWi0",
  "https://youtu.be/AWlqEBDS65E",
  "https://www.youtube.com/watch?v=iIHRXQeXN4Y",
  "https://youtu.be/Ts85yaHhvus",
  "https://youtu.be/rhC9KVo7S0c",
  "https://www.youtube.com/watch?v=KM54Hczsz4U&feature=youtu.be",
  "https://youtu.be/1R42mFx3_ss",
  "https://www.youtube.com/watch?v=briK2ASGpI4",
  "https://youtu.be/lxjSZGNVU_k",
  "https://www.youtube.com/watch?v=AVN-wjJxOSc",
  "https://youtu.be/kSthwKHChs0?t=1m47s",
  "https://www.youtube.com/watch?v=8wl5EkdNPfo",
  "https://www.youtube.com/watch?v=A62gHhEQvJQ",
  "https://www.youtube.com/watch?v=WnPuzVYgnIw",
  "https://www.youtube.com/watch?v=AIPT_t0YPDc",
  "https://youtu.be/9LbhZbcn0TQ",
  "https://www.youtube.com/watch?v=B35YAcdkmVI&feature=youtu.be",
  "https://www.youtube.com/watch?v=MUhLPqFBMjU",
  "https://www.youtube.com/watch?v=X1KXdRurk-o&feature=youtu.be",
  "https://www.youtube.com/watch?v=50CZgtpzxLE",
  "https://www.youtube.com/watch?v=4i1fetyzwnk&feature=youtu.be",
  "https://www.youtube.com/watch?v=UQhADGsTK2s",
  "https://youtu.be/AnOn3Euk-X4",
  "https://youtu.be/hlTs4IF_pnI",
  "https://www.youtube.com/watch?v=-pC87aYXiFI",
  "https://www.youtube.com/watch?v=mwgZ3KT3jHE",
  "https://www.youtube.com/watch?v=ecnJIBoo8G4",
  "https://youtu.be/aRsWk4JZa5k",
  "https://youtu.be/RSuLFvalhnQ?t=46",
  "https://www.youtube.com/watch?v=o2Ldkvcon-c",
  "https://www.youtube.com/watch?edit=vd&v=T_uIuRe1Ap4",
  "https://www.youtube.com/watch?v=oKBsmt6jxZ8",
  "https://www.youtube.com/watch?v=3nGV6PsrYkw",
  "https://m.youtube.com/watch?v=p8gC7P7gMr4",
  "https://www.youtube.com/watch?v=wi3aD39OMYA",
  "https://youtu.be/g6h2kNWXnU4",
  "https://www.youtube.com/watch?v=Z_pTdWN1fFY&feature=youtu.be",
  "https://youtu.be/WmOUDf8ufbQ?t=16",
  "https://www.youtube.com/watch?v=qeD7cxu66Ds",
  "https://youtu.be/GUzoh6oDy4Y?t=144",
  "https://www.youtube.com/watch?v=h9hGc1bc610",
  "https://www.youtube.com/watch?v=c_bFnR89cvo",
  "https://www.youtube.com/watch?v=i8CwHvyFwWM",
  "https://youtu.be/nYpgfGO4RAA",
  "https://www.youtube.com/watch?v=kOxXGbtHqqI",
  "https://youtu.be/wpBwVlhSEqs?t=7",
  "https://youtu.be/dpbRC6aXa20",
  "https://youtu.be/7rYkz0XVevQ",
  "https://www.youtube.com/watch?v=gQLHsoNQetw",
  "https://youtu.be/AP3qecBHeTA?t=12s",
  "https://m.youtube.com/watch?v=nAop9MZtX0M",
  "https://www.youtube.com/watch?v=H8mvIVxLjXU",
  "https://www.youtube.com/watch?v=RO8NzuoOaLI",
  "https://www.youtube.com/watch?v=wz62OMWaklE",
  "https://www.youtube.com/watch?v=4fO1auNK5Ng",
  "https://www.youtube.com/watch?v=V9RYV5zNe1M&t=0s",
  "https://youtu.be/KaxWAwdJMA4",
  "https://www.youtube.com/watch?v=hsT_sw10Jfw",
  "https://www.youtube.com/watch?v=vevl4zROq64",
  "https://www.youtube.com/watch?v=3xtasAvlUEU",
  "https://www.youtube.com/watch?v=ahBXYLHRPic",
  "https://www.youtube.com/watch?v=Ybogc4Cn38A",
  "https://www.youtube.com/watch?v=xDLMibqqRKU",
  "https://youtu.be/KEIG6JibhLo",
  "https://youtu.be/3Q-i2hWCxPE",
  "https://www.youtube.com/watch?v=LDuIiUfxcyo",
  "https://www.youtube.com/watch?v=mHNOQ510bQM",
  "https://youtu.be/AkW-EAkClbE",
  "https://www.youtube.com/watch?v=3aZuj_SDqDo",
  "https://www.youtube.com/watch?v=vw9zyxm860Q",
  "https://www.youtube.com/watch?v=sA_eCl4Txzs",
  "https://youtu.be/bh8tMfzxgFg",
  "https://youtu.be/nCCEfBIslOM",
  "https://youtu.be/mYkB5f1X-yM?t=4s",
  "https://youtu.be/kqCCvyIWKXk",
  "https://www.youtube.com/watch?v=bukWqcxgIPM&feature=youtu.be",
  "https://www.youtube.com/watch?v=l6Ip3SjS8V0",
  "https://www.youtube.com/watch?v=W4c_l0s814g",
  "https://youtu.be/0a_M9VsZ6Lk?t=32",
  "https://www.youtube.com/watch?v=CvXR7T3HZWc",
  "https://www.youtube.com/watch?v=7xZDaw2H9GU",
  "https://www.youtube.com/watch?v=Q63Uw2qSmzc",
  "https://youtu.be/i5cgzXpZMZU",
  "https://www.youtube.com/watch?v=DPKQwfIe5YQ",
  "https://www.youtube.com/watch?v=f6Qpyy74cFo&ab_channel=NeonJohn",
  "https://www.youtube.com/watch?v=3aZuj_SDqDo&t=2s",
  "https://youtu.be/oaUKM3PiIKk?t=1s",
  "https://www.youtube.com/watch?v=TPnCvAYIZsY",
  "https://youtu.be/ztNR21g3wCU",
  "https://youtu.be/LorNjh7o-yM",
  "https://www.youtube.com/watch?v=5C0SlqXCKKU",
  "https://youtu.be/JXb4Lvn1Nt0",
  "https://youtu.be/R5dqC0IwlMA?t=6s",
  "https://www.youtube.com/watch?v=RPFZgk_O8Ok",
  "https://www.youtube.com/watch?v=deuM4ej2zcg",
  "https://www.youtube.com/watch?v=TFp8gUu1RRw&feature=youtu.be",
  "https://www.youtube.com/watch?v=dQ7Gu5hyDXk&feature=youtu.be",
  "https://youtu.be/6dFj_YhK5Mo",
  "https://www.youtube.com/watch?v=EmEbCd6p2Ww",
  "https://www.youtube.com/watch?v=o2CYdhBNFjo",
  "https://www.youtube.com/watch?v=ah7PBFt1meA&feature=youtu.be",
  "https://www.youtube.com/watch?v=7IJ_W4akSls",
  "https://www.youtube.com/watch?time_continue=8&v=8tZBM85cteg",
  "https://youtu.be/EixPRMs2jbY",
  "http://www.youtube.com/watch?v=KWTxPwtCCGQ&t=3m38s",
  "https://youtu.be/O7M8zWX7s0o",
  "https://youtu.be/T25upLp8Ri0?t=5",
  "https://www.youtube.com/watch?v=YrKMiE9WII0",
  "https://www.youtube.com/watch?v=7E5nwGpkXso",
  "https://youtu.be/xYDpz4nBdNg",
  "https://www.youtube.com/watch?v=6Ne82sGiP5I&feature=youtu.be",
  "https://www.youtube.com/watch?v=THPGPgpBSvk",
  "https://www.youtube.com/watch?v=DUiZLPFK_Ng",
  "https://youtu.be/HXhdEIsMbhU",
  "https://youtu.be/HmOzEkAY7ck",
  "https://www.youtube.com/watch?v=D-UmfqFjpl0",
  "https://youtu.be/mdLycSfWj1s",
  "https://www.youtube.com/watch?v=7zQ2L92NZWw",
  "https://www.youtube.com/watch?v=t_LJtG2gXSc",
  "https://www.youtube.com/watch?v=JhDIILjlhBQ",
  "https://www.youtube.com/watch?v=63kblQUkWqE&list=PLkoYlLJnTbuCb6k23gselrjQdY0CgalO7",
  "https://www.youtube.com/watch?v=LcT2Cx1Rbd8",
  "https://youtu.be/aqyThKqAdok",
  "https://youtu.be/uCcKjRRaHoc?t=16m30s",
  "https://www.youtube.com/watch?v=3Jji78uEW14",
  "https://www.youtube.com/watch?v=yvNhQhMg9yw&feature=youtu.be",
  "https://www.youtube.com/watch?v=ZmDWYH9cptY",
  "https://youtu.be/nixy2vvqB10?t=2s",
  "https://www.youtube.com/watch?v=l0DDOWogc6A",
  "https://youtu.be/gU6krJqnqyU",
  "https://www.youtube.com/watch?v=yN6FsToW2w4",
  "https://www.youtube.com/watch?v=si6gJh2mKqk",
  "https://youtu.be/nN1Bh07HkxE",
  "https://www.youtube.com/watch?v=lEA5KNKpIjo",
  "https://www.youtube.com/watch?v=53G5x_5PExQ",
  "https://www.youtube.com/watch?v=mIl_SrYomOk",
  "https://www.youtube.com/watch?v=lHH3s5u_2bc",
  "https://www.youtube.com/watch?v=E4AouYzN3O0&feature=youtu.be",
  "https://www.youtube.com/watch?time_continue=9&v=-10DgWsSZNc",
  "https://www.youtube.com/watch?v=qaHL8jGJBOw",
  "https://youtu.be/-lcV9WYD6T8",
  "https://youtu.be/sIUXpLQ2VR0",
  "https://youtu.be/eJHXzapKaTo",
  "https://youtu.be/sC_Q7VZK03E",
  "https://www.youtube.com/watch?v=HK1TEeqq4gU",
  "https://www.youtube.com/watch?v=nAjh3fJuI6k",
  "https://youtu.be/x9Fa529hlBY?t=10s",
  "https://www.youtube.com/watch?v=ymb9gsl_x1U",
  "https://www.youtube.com/watch?v=NwSYhDpTYGw",
  "https://youtu.be/DCQ_roSEKeE?t=2s",
  "https://youtu.be/K3CTdTtT4ng",
  "https://www.youtube.com/watch?v=CS5GThRl_XE",
  "https://www.youtube.com/watch?v=CriuV-yNqv4",
  "https://www.youtube.com/watch?v=XfCWbNWkPqU",
  "https://youtu.be/6622xan963k?t=1m23s",
  "https://www.youtube.com/watch?v=QgWRmpACPJo",
  "https://www.youtube.com/watch?v=eQqO3kFaV2w",
  "https://www.youtube.com/watch?v=EUbN1OIjn-g&feature=youtu.be",
  "https://www.youtube.com/watch?v=IvytpSuZQq4",
  "https://www.youtube.com/watch?v=aoub6WFaM2w",
  "https://youtu.be/p9fyf3VAeVE",
  "https://youtu.be/x4dSIKyzb-c",
  "https://youtu.be/MAQ2Ca72FYo",
  "https://www.youtube.com/watch?v=NaoRiJg8zrE",
  "https://www.youtube.com/watch?v=520uma9Eojk",
  "https://www.youtube.com/watch?v=QLSmMCetSgQ",
  "https://www.youtube.com/watch?v=sIUXpLQ2VR0",
  "https://youtu.be/mnrZ1tAFwDE",
  "https://www.youtube.com/watch?v=-nD9FsSdLDo",
  "https://youtu.be/rTOuFCVz_Ug",
  "https://youtu.be/h-MQjiumoNg",
  "https://www.youtube.com/watch?v=y-P0m0M_8pc",
  "https://www.youtube.com/watch?v=q7gQL9809y4",
  "https://youtu.be/DpaTCCphYk4",
  "https://youtu.be/yr-V6GPJWdI",
  "https://youtu.be/jFsTNy1uEa8",
  "https://www.youtube.com/watch?v=054nAOttL0E",
  "https://www.youtube.com/watch?v=i5cgzXpZMZU",
  "https://youtu.be/39I5_ZffupQ",
  "https://www.youtube.com/watch?v=meEo5NPnDw4",
  "https://www.youtube.com/watch?v=az3TWkjQ0J4",
  "https://www.youtube.com/watch?v=WAlB9OxY6hU",
  "https://youtu.be/-fwTNiBzQZQ",
  "https://www.youtube.com/watch?v=SePVlroq6AI",
  "https://www.youtube.com/watch?v=Oct2xKMGOno",
  "https://www.youtube.com/watch?v=TxjERq2z1Cw&feature=youtu.be",
  "https://www.youtube.com/watch?v=N9dIjMv_Rrk&feature=youtu.be",
  "https://www.youtube.com/watch?v=chsDFYAv5ZE",
  "https://youtu.be/C4EHM-tweXY?t=20",
  "https://www.youtube.com/watch?v=Z7zG5OD2dOY",
  "https://youtu.be/L_OuM9cu-G8",
  "https://www.youtube.com/watch?v=sYQcmMH1ILQ",
  "https://www.youtube.com/watch?v=CE6dj49cusc",
  "https://youtu.be/1c_ubNku3_E",
  "https://youtu.be/UvgtL0Gq7Vo",
  "https://www.youtube.com/embed/ZQl-GXPBbTo?start=6&end=36",
  "https://www.youtube.com/watch?v=6KcE869pLzQ&t",
  "https://www.youtube.com/watch?v=oCIo4MCO-_U",
  "https://youtu.be/_n8MJTnikCU",
  "https://youtu.be/iUJ8r4ReHog",
  "https://www.youtube.com/watch?v=gM6v7_8raYI",
  "https://youtu.be/iiHIF2XACmI?t=18s",
  "https://youtu.be/l5C2IlmbL6M",
  "https://youtu.be/-ZviKph_pFY",
  "https://youtu.be/Y46LAqIkaJY",
  "https://www.youtube.com/watch?v=YHf7e67T54Y",
  "https://youtu.be/eu3H4_vqal8",
  "https://www.youtube.com/watch?v=K1Y6PchDYfw&feature=youtu.be",
  "https://www.youtube.com/watch?v=9G7aT6p_aGk",
  "https://www.youtube.com/watch?v=n1mEjd6CCGE",
  "https://www.youtube.com/watch?v=RPzxixRpCBg",
  "https://youtu.be/9S7TcdkUB0w",
  "https://youtu.be/tyWShs8_hQE",
  "https://www.youtube.com/watch?v=LzOQUQXlupY",
  "https://youtu.be/oL5ZWotGaxM",
  "https://www.youtube.com/watch?v=KYUHihDAYBw",
  "https://www.youtube.com/watch?v=KTy8F692cpk",
  "https://youtu.be/xELzAbyqGwU",
  "https://www.youtube.com/watch?v=HnDP2ryclBs",
  "https://www.youtube.com/watch?v=CG9lZofMUsI",
  "https://youtu.be/3a9ZQpUtYF8",
  "https://www.youtube.com/watch?v=NhYz-Zij630",
  "https://www.youtube.com/watch?v=18TNjthlB44",
  "https://youtu.be/EQK--MEEHDU",
  "https://youtube.com/watch?v=pqC4VGguPLY",
  "https://www.youtube.com/watch?v=L0oN8xDArHI",
  "https://youtu.be/ei0ds1Dj6_c",
  "https://youtu.be/gSeJgbcubh0",
  "https://www.youtube.com/watch?v=u_CN-QcreHo&t=30s",
  "https://www.youtube.com/watch?v=6QpfKDOJ1yA",
  "https://www.youtube.com/watch?v=oLE3YYzIZDk",
  "https://www.youtube.com/watch?v=0_XHqQTKZhg",
  "https://www.youtube.com/watch?v=XKuNHwsnIis",
  "https://www.youtube.com/watch?v=uTak8cZJE74",
  "https://www.youtube.com/watch?v=aEm514hgRmA",
  "https://youtu.be/dmylLVOqqZU",
  "https://www.youtube.com/watch?v=mBDwtxw_jX0",
  "https://www.youtube.com/watch?v=qgIvI-B2HqE",
  "https://www.youtube.com/watch?v=F7awHfYfEsM",
  "https://youtu.be/ac90YhnPZzI",
  "https://youtu.be/7RUPFp0TmSQ",
  "https://youtu.be/X_GT1MREo18",
  "https://www.youtube.com/watch?v=mdkpapVUvf4",
  "https://www.youtube.com/watch?v=9Ammp3jlFLI&feature=youtu.be",
  "https://www.youtube.com/watch?v=xH948n-AZ4s&feature=youtu.be",
  "https://www.youtube.com/watch?v=2AqL05iSq7A",
  "https://youtu.be/r01iD7j4pPI",
  "https://www.youtube.com/watch?v=3eBwB-mx3Js",
  "https://m.youtube.com/watch?v=R0Wi1QI9p_8&feature=youtu.be",
  "https://www.youtube.com/watch?v=2si5fDHKNu8&feature=youtu.be",
  "https://www.youtube.com/watch?v=4dv2fYRQjow",
  "https://youtu.be/B_pP9PNEVTU",
  "https://www.youtube.com/watch?v=GoUWdzNp9SM",
  "https://youtu.be/RuhNpjAKnX8",
  "https://www.youtube.com/watch?v=TjKygWF5mKA",
  "https://www.youtube.com/watch?v=heIUNDzn2Uo",
  "https://www.youtube.com/watch?v=dqPFdWHJje0",
  "https://www.youtube.com/watch?v=_HsWY43UY2U",
  "https://www.youtube.com/watch?v=mjqcKLBwWY0",
  "https://youtu.be/aI10UC2Tzs4?t=1s",
  "https://www.youtube.com/watch?v=W3axojj-SQw",
  "https://www.youtube.com/watch?v=ab59zcsV35k",
  "https://youtu.be/RneSBgOrTnM?t=26",
  "https://www.youtube.com/watch?v=VS98WnEAasM",
  "https://www.youtube.com/watch?v=VVdMuTN3Vs4&feature=youtu.be",
  "https://www.youtube.com/watch?v=RLzdlGqFWZE",
  "https://www.youtube.com/watch?v=2eBpbTwc5fE",
  "https://m.youtube.com/watch?v=dRv4SVYpejE",
  "https://youtu.be/rTu3kD9gohg",
  "https://youtu.be/zSvXsizJhmI",
  "https://www.youtube.com/watch?v=kzPS0WiuXaM&feature=youtu.be",
  "https://www.youtube.com/watch?v=4G-xsqObbr0",
  "https://youtu.be/4kV5yJLbibM",
  "https://youtu.be/5qrMY6CDT2M",
  "https://www.youtube.com/watch?v=gTeQRpuVEkM",
  "https://www.youtube.com/watch?v=s5day1C5zKk",
  "https://www.youtube.com/watch?v=HsWYmQBdp4U",
  "https://www.youtube.com/watch?v=QQBSYe0BTsw",
  "https://youtu.be/1ejPlUSz1xI",
  "https://www.youtube.com/watch?v=0mNQT4ETjHc",
  "https://youtu.be/ye79ke_n6ds",
  "https://youtu.be/xh1lmPvfrpk?t=6s",
  "https://youtu.be/-xA3ikcSssI",
  "https://www.youtube.com/embed/qaPDFcc1EWU?start=10&end=38&version=3",
  "https://www.youtube.com/watch?v=HXhdEIsMbhU",
  "https://www.youtube.com/embed/7-NOZU2iPA8?start=102&end=123",
  "https://youtu.be/vjWA4e7U7_Y",
  "https://www.youtube.com/watch?v=-SpQ73VAwvE",
  "https://www.youtube.com/watch?v=1aCEHDr2_Tc",
  "https://www.youtube.com/watch?v=EsgkeEUo9mI",
  "https://youtu.be/pVTJPiKyKrQ",
  "https://www.youtube.com/watch?v=1n54WLCwmwY",
  "https://www.youtube.com/watch?v=awFOWkKPCBU&feature=youtu.be",
  "https://youtu.be/s6ktteQSr1w",
  "https://www.youtube.com/watch?v=zSvXsizJhmI&feature=youtu.be",
  "https://youtu.be/cXkWC-gJqnA",
  "https://youtu.be/u2lC-7ylbhQ",
  "https://www.youtube.com/watch?v=bQRyvS_5Mbk",
  "https://www.youtube.com/watch?v=OD5901bKfwc",
  "https://www.youtube.com/watch?v=6B84XHx2IaQ",
  "https://youtu.be/KjJfi0ieAFA",
  "https://www.youtube.com/watch?v=liEXSGgG83E",
  "https://youtu.be/vFkKfNhPnZM",
  "https://youtu.be/b305CaQvQ44",
  "https://www.youtube.com/watch?v=GC3kJya-p4U",
  "https://www.youtube.com/watch?v=0nM4p-NQDYQ",
  "https://www.youtube.com/watch?v=jZ5Rcb0flNM",
  "https://www.youtube.com/watch?v=diWSQOC2NeM",
  "https://www.youtube.com/watch?v=aWi7UHGVA_Y",
  "https://www.youtube.com/watch?v=1R2xcL34fRg",
  "https://youtu.be/imj9Zzt_xwQ?t=17",
  "https://www.youtube.com/watch?v=v4H8EkfSAkQ&feature=youtu.be",
  "https://www.youtube.com/watch?v=oPSOKlptLPo",
  "https://www.youtube.com/watch?v=tRapAI2SVUg",
  "https://youtu.be/IxFfxTZA6ao?t=19",
  "https://www.youtube.com/watch?v=ZqyRuiZIepM",
  "https://www.youtube.com/watch?v=UZDuGjvXd1Q",
  "https://www.youtube.com/watch?v=9x7r1er6Ljw",
  "https://youtu.be/Je5u_dMEIUw",
  "https://www.youtube.com/watch?v=5z482NBBuhc",
  "https://www.youtube.com/watch?v=Yq1eASjHkzw",
  "https://youtu.be/gGAjbKG7YPw",
  "https://youtu.be/zQg67tYE75U",
  "https://www.youtube.com/watch?v=nkx1FbldYyM",
  "https://youtu.be/SApUmi02qIA?list=PLEzN-y0ZMgpsWiqmLIFKx47GYzGCkN2wf",
  "https://www.youtube.com/watch?t=&v=Dalgrd4CpdE",
  "https://youtu.be/Mds1BQhU_9k",
  "https://www.youtube.com/watch?v=-XmYApyQVu8&feature=youtu.be",
  "https://www.youtube.com/watch?v=T2P6A-mXfUQ",
  "https://youtu.be/3mEJ13N-O6I",
  "https://youtu.be/mG1gsysxjF4",
  "https://www.youtube.com/watch?v=jp9nIsyy_Io",
  "https://youtu.be/b0A4Ik2qMcQ",
  "https://youtu.be/PhGGTjIaSYQ?t=6",
  "https://youtu.be/Ab6EhLqjZQ8",
  "https://youtu.be/mdXikpkCP1I",
  "https://www.youtube.com/watch?v=Eos_o9RP14s",
  "https://www.youtube.com/watch?v=S2SPfrnjKLc",
  "https://www.youtube.com/watch?v=36WvWXbL1sQ",
  "https://youtu.be/C4MAYarhO1o",
  "https://youtu.be/pBFZBf8bQtA?t=2",
  "https://www.youtube.com/watch?v=X-ZAAUQ99NE",
  "https://www.youtube.com/watch?v=xmYR6jimrVI",
  "https://www.youtube.com/watch?v=Kxcwr_v-s1o",
  "https://youtu.be/baIsOYyWXh8",
  "https://youtu.be/cRosTcfKANk",
  "https://youtu.be/tf6uAsrROhs",
  "https://youtu.be/APHcYegE6ns",
  "https://youtu.be/x02VAUa0kh0?t=6s",
  "https://www.youtube.com/watch?v=CAeEnCVKXKk",
  "https://www.youtube.com/watch?v=rhlOEKnfp2Y",
  "https://www.youtube.com/watch?v=frKyaFTeC7c&feature=youtu.be",
  "https://www.youtube.com/watch?v=CNEuzhr7zQU",
  "https://www.youtube.com/watch?v=DEakgiKUGxM",
  "https://www.youtube.com/watch?v=joVwSnWVu90",
  "https://www.youtube.com/watch?v=McqpDvTR7_o",
  "https://youtu.be/ObIOTyVTcRs",
  "https://www.youtube.com/watch?v=dBa8p0NFwM8&feature=youtu.be&start=18&end=22",
  "https://www.youtube.com/watch?v=eI7FcfttAIE&feature=youtu.be",
  "https://youtu.be/hVdNsKhkuJY?t=6",
  "https://www.youtube.com/watch?v=zJ26tVj8cRg&feature=youtu.be",
  "https://www.youtube.com/watch?v=n5z2_fggZlI",
  "https://www.youtube.com/watch?v=mMiBDW-6WK0&t=0s",
  "https://www.youtube.com/watch?v=yvXEB4r64Ns",
  "https://www.youtube.com/watch?v=38v51JvIv0A",
  "https://youtu.be/FPFfMVR9PeU",
  "https://www.youtube.com/watch?v=xTtx_JapekE",
  "https://youtu.be/Ly-wVQ_dbk0",
  "https://www.youtube.com/watch?v=YoFa6Oy_DCQ",
  "https://www.youtube.com/watch?v=0R0WvE8Wzec",
  "https://www.youtube.com/watch?v=V4rUiV_Hh74",
  "https://www.youtube.com/watch?v=3XUFmVU3o4c",
  "https://www.youtube.com/watch?v=TwpKgIuq7Ik",
  "https://youtu.be/A21wxZKiao8",
  "https://youtu.be/1ecbWh4OR1g",
  "https://www.youtube.com/watch?v=QUBL4ZBE2Kw",
  "https://youtu.be/759Cj4GPrqk",
  "https://youtu.be/lbRki94nack?t=6",
  "https://youtu.be/O3VR9DLpr8o?t=1m",
  "https://youtu.be/DJZzGJ9SYzA",
  "https://youtu.be/AhtMBjZUglY",
  "https://www.youtube.com/watch?v=T2XDA-MGCCc",
  "https://www.youtube.com/watch?v=9VBSH-2fMuw",
  "https://www.youtube.com/watch?v=xGyWuRcHaJI",
  "https://youtu.be/1euT7GsUuhY",
  "https://youtu.be/pAFfSRU6dnM",
  "https://www.youtube.com/watch?v=Hxjofn2bYE8",
  "https://www.youtube.com/watch?v=VkYTXbpveTA",
  "https://www.youtube.com/watch?v=ISyc-ja-5xw",
  "https://www.youtube.com/watch?v=wxGya78iJdo",
  "https://www.youtube.com/watch?v=Tc-6Z8TUI7c",
  "https://youtu.be/GLgIT3s4GO8",
  "https://youtu.be/M3sm6PUjUuQ",
  "https://youtu.be/lcNsMWy7eXA?t=4s",
  "https://www.youtube.com/watch?v=vlXyj3lthmc",
  "https://youtu.be/U6djmXxQW70",
  "https://www.youtube.com/watch?v=pUMokcCwQL8",
  "https://youtu.be/yqLxzOWW_M8?t=10",
  "https://www.youtube.com/watch?v=eI6wA4_l5cE&t=7s",
  "https://youtu.be/OHaxO-BC8x0",
  "https://youtu.be/Odlt4i65OKY",
  "https://youtu.be/cyn6XYW2T64?t=9s",
  "https://youtu.be/l2Cf-6vKVWg?t=14s",
  "https://www.youtube.com/watch?v=hHnVeq20oPM",
  "https://www.youtube.com/watch?v=I7O4dB_4y5w",
  "https://www.youtube.com/watch?v=qlDPtFTuZ7I&feature=youtu.be&t=8s",
  "https://www.youtube.com/watch?v=D6Vptpml158",
  "https://youtu.be/p_NwN3kkfGs?t=57s",
  "https://youtu.be/np5KaNhxzQ0",
  "https://www.youtube.com/watch?v=Zljgcc-RnFA&lc=z22ystdhlovqvdj2nacdp43aoott0oaaf11ed55myv1w03c010c",
  "https://youtu.be/kTMbYaDgXck",
  "https://youtu.be/RQy7DVpfMaA",
  "https://www.youtube.com/watch?v=RIVefdIvn3M&feature=youtu.be",
  "https://www.youtube.com/watch?v=_kVAq5zomBA",
  "https://www.youtube.com/watch?v=lGUylg09Ikw&feature=youtu.be",
  "https://youtu.be/Q-NwTcTdxi0",
  "https://youtu.be/-hVJEYdgetc?t=3s",
  "https://www.youtube.com/watch?v=9WjCi9OPkHs",
  "https://www.youtube.com/embed/lCzoNFeTg5M?end=30",
  "https://youtu.be/0qTCLS-AvEM",
  "https://youtu.be/3aNMGWadgWA?t=6s",
  "https://www.youtube.com/watch?v=WIrWyr3HgXI",
  "https://www.youtube.com/watch?v=2pJlpCfueV0&feature=youtu.be",
  "https://www.youtube.com/watch?v=VlmCWPFOvh4&feature=youtu.be",
  "https://youtu.be/3NDK2AGpwW8?t=1s",
  "https://www.youtube.com/watch?v=PfDFeqPutZA&feature=youtu.be",
  "https://youtu.be/cFA8rLknjms",
  "https://youtu.be/xtJaV_gSOUQ",
  "https://www.youtube.com/watch?v=kRM2uEYexu0",
  "https://www.youtube.com/watch?v=az8y-yS5iMY",
  "https://www.youtube.com/watch?v=oCENncUNj6A",
  "https://www.youtube.com/watch?v=gNOmZ4l4v54'",
  "https://youtu.be/OD2uL5JqUwY",
  "https://www.youtube.com/watch?v=X3Py7ecgUE0&t=0m13s",
  "https://youtu.be/auNe1JYz3hg",
  "https://www.youtube.com/watch?v=A4MSceT4hzk",
  "https://www.youtube.com/watch?v=W4c_l0s814g&feature=youtu.be",
  "https://www.youtube.com/watch?v=4AGihAcdrAU",
  "https://www.youtube.com/watch?v=Q5rnz54WoLE",
  "https://youtu.be/WmOUDf8ufbQ?t=16s",
  "https://youtu.be/iBxRQ5dqVTA?t=10s/",
  "https://youtu.be/uz3Rj8TXbyA",
  "https://www.youtube.com/watch?v=mrm7On6AK-k",
  "https://www.youtube.com/watch?v=9g931LC43MQ",
  "https://www.youtube.com/watch?v=P6dGO4nfmDk",
  "https://www.youtube.com/watch?v=nH8tLx3vIWs",
  "https://www.youtube.com/watch?v=yuP2MIARQSA",
  "https://www.youtube.com/watch?v=mVt_1lGTUcg",
  "https://www.youtube.com/watch?v=up7c9nYeyUE&feature=youtu.be",
  "https://youtu.be/uNs4I3pTbQ0",
  "https://www.youtube.com/watch?v=8htF8gGUAjk",
  "https://youtu.be/G9X0FDwAAic",
  "https://youtu.be/gSDZt1WKcu8",
  "https://www.youtube.com/watch?v=zW1uN0SjKPY&feature=youtu.be",
  "https://youtu.be/c38_1E_esPc",
  "https://www.youtube.com/watch?v=syrQsy6pVkQ",
  "https://youtu.be/K3eUM4OmpvI",
  "https://youtu.be/O_zx7jPLmyk?t=16s",
  "https://www.youtube.com/watch?v=4SgEeXPldjQ",
  "https://www.youtube.com/watch?v=e1SArILAHPw",
  "https://www.youtube.com/watch?v=bphKQo6U3QM",
  "https://www.youtube.com/watch?v=jNF3J56JOCs",
  "https://m.youtube.com/watch?v=ZITP93pqtdQ",
  "https://youtu.be/SIhBc0cl3r4?t=2s",
  "https://www.youtube.com/watch?v=4ASL-D9B6HI",
  "https://youtu.be/IRFV3VAgxjw",
  "https://www.youtube.com/watch?v=B-vPMz0frx4#t=12s",
  "https://www.youtube.com/watch?v=_0E49O10HSg",
  "https://youtu.be/c3oc8XhSRaE",
  "https://youtu.be/4In8NRkOUb0",
  "https://www.youtube.com/watch?v=m2zgaGwf4Ng",
  "https://www.youtube.com/watch?v=NlGoV3jTCpA&feature=youtu.be",
  "https://www.youtube.com/watch?v=tUOrwWge62A",
  "https://www.youtube.com/watch?v=4AxvYtvQp0A",
  "https://youtu.be/bZdy6RYe6FA",
  "https://youtu.be/oMaNO1RNFbA?t=6s",
  "https://youtu.be/Oj9E0bWDmyY",
  "https://www.youtube.com/watch?v=-0YP8_M6TC8",
  "https://www.youtube.com/watch?v=5eArCJcmgro",
  "https://www.youtube.com/watch?v=JYri26Ox01E",
  "https://www.youtube.com/watch?v=fMYz8GlELk0",
  "https://www.youtube.com/watch?v=ubTaU1oMMvE",
  "https://youtu.be/0hsDKwjv8hY",
  "https://www.youtube.com/watch?v=shuyUCJTRSM&2",
  "https://www.youtube.com/watch?v=QLp6YJ93Q7I",
  "https://m.youtube.com/watch?v=XIadiBHvMXg",
  "https://www.youtube.com/watch?v=EqDD_GzWTqE",
  "https://youtu.be/1-enGQkPY4w",
  "https://www.youtube.com/watch?v=THNPmhBl-8I",
  "https://www.youtube.com/watch?v=W0aG9cTKLdk",
  "https://youtu.be/LMdPfMpZv9o",
  "https://www.youtube.com/watch?v=7csxLRm2yvM",
  "https://www.youtube.com/watch?v=hJ9yBgTp9UQ",
  "https://youtu.be/XiRUhLOxtJM",
  "https://www.youtube.com/watch?v=h9qvUwofmwI",
  "https://www.youtube.com/watch?v=mVCC-PH2p4c&feature=youtu.be",
  "https://youtu.be/9dlETP7_0GY?t=7",
  "https://www.youtube.com/watch?v=_S5jFvG81XM",
  "https://www.youtube.com/watch?v=b5YjdTMY10s",
  "https://youtu.be/BHaOgDxt6aE",
  "https://www.youtube.com/watch?v=z6VJAwSnxu0&feature=youtu.be",
  "https://www.youtube.com/watch?v=44depsi7b1E",
  "https://youtu.be/GXPIH6iRNAY",
  "https://www.youtube.com/watch?v=87WaaTGf8eY&feature=youtu.be",
  "https://www.youtube.com/watch?v=CdKvZDQt96o",
  "https://youtu.be/G7OshCHsfJI",
  "https://youtu.be/m-KLAqyUg20",
  "https://youtu.be/EretGjnyLKM",
  "https://youtu.be/0nKBBJh7K9A",
  "https://youtu.be/O85gKZ2YEE8",
  "https://youtu.be/HjuievA2VfE",
  "https://youtu.be/Jzu3kOx9iJg",
  "https://www.youtube.com/watch?v=DfJuMRThxXA",
  "https://www.youtube.com/watch?time_continue=9&v=CKgHD8y9-wI",
  "https://youtu.be/Y-BnvxPIsts",
  "https://youtu.be/oara8uCIV6o",
  "https://youtu.be/CnK-RGhUoYE?t=8",
  "https://youtu.be/tW8HNAlUXxU?t=102",
  "https://youtu.be/JCE2wo2G6j4",
  "https://youtu.be/cHl1B-ZjIBY",
  "https://youtu.be/jIKwCCn5fMM",
  "https://www.youtube.com/watch?v=9KZ-ptvWD6U",
  "https://www.youtube.com/watch?v=bQh8UNdXSag",
  "https://youtu.be/D4GgtTmNhuQ",
  "https://www.youtube.com/watch?v=g6BT9NJ1acs&feature=youtu.be",
  "https://www.youtube.com/watch?v=cTrlEoRWsDM&feature=youtu.be",
  "https://www.youtube.com/watch?v=ofQ0iOJPVe0",
  "https://www.youtube.com/watch?v=H3IC5mG_h1o",
  "https://www.youtube.com/watch?v=KtAeizWZ39w",
  "https://www.youtube.com/watch?v=1yj7-FcbKY4",
  "https://www.youtube.com/watch?v=3Z0vKaIRrmw",
  "https://www.youtube.com/watch?v=yV0bcwvIn5A",
  "https://www.youtube.com/watch?v=Sj-MmAwGGsU",
  "https://www.youtube.com/watch?v=XI-gZ7nRnGM",
  "https://www.youtube.com/watch?v=T9u83yN5Yp0",
  "https://www.youtube.com/watch?v=6gsxfRzjfxA",
  "https://www.youtube.com/watch?v=5J9adRoRFa0",
  "https://youtu.be/ZPCwA3poTNU",
  "https://www.youtube.com/watch?v=Dafy2r2pPmk",
  "https://www.youtube.com/watch?v=EL2PzrtFuPU&feature=youtu.be",
  "https://m.youtube.com/watch?v=cg3GA237r8U",
  "https://www.youtube.com/watch?v=n-3ov2LL5i8",
  "https://www.youtube.com/watch?v=EqpfI_LcU50",
  "https://www.youtube.com/watch?v=imkDSPD_RwY",
  "https://youtu.be/fpcYQRTuQig",
  "https://www.youtube.com/watch?v=dvMUWjhauXQ",
  "https://youtu.be/d4AiPSgjIxw",
  "https://www.youtube.com/watch?v=8NvwPnLagB8",
  "https://www.youtube.com/watch?v=MsXGgZJN-IM",
  "https://youtu.be/gHSaS_gk6qU",
  "https://www.youtube.com/watch?v=L1SKf9YU4QQ",
  "https://www.youtube.com/watch?v=nYKSYleKueg&feature=youtu.be",
  "https://www.youtube.com/watch?v=mG17S2zSg1U",
  "https://m.youtube.com/watch?v=n3SIT5xlLxI",
  "https://www.youtube.com/watch?v=7ihPJY4QcaI&feature=youtu.be",
  "https://youtu.be/80i8di0L9Do",
  "https://www.youtube.com/watch?v=xeG9FLmANpk&feature=youtu.be",
  "https://youtu.be/amEUvMMjsQQ",
  "https://www.youtube.com/watch?v=L67fcnYYQeY",
  "https://www.youtube.com/watch?v=OWNb5UpxrKI",
  "https://youtu.be/Mrpw9m91Igg",
  "https://www.youtube.com/watch?v=8hxk01j00V4",
  "https://youtu.be/Gra46TpjH-I",
  "https://www.youtube.com/watch?v=cSC9nFZ63BU",
  "https://www.youtube.com/watch?v=IBDJF4EryN0",
  "https://youtu.be/Z17krflSpBM",
  "https://youtu.be/9rszbmrE4jU",
  "https://www.youtube.com/watch?v=lpvT-Fciu-4",
  "https://www.youtube.com/watch?v=2SZvQ0BA0xs",
  "https://www.youtube.com/watch?v=WuQDYOrh5wM",
  "https://www.youtube.com/watch?v=6TxjrHPHypA",
  "https://youtu.be/bdO7fXbf4z8",
  "https://www.youtube.com/watch?v=PfDTTwqaErw",
  "https://youtu.be/e5GsPxsypX4",
  "https://www.youtube.com/watch?v=aw6sstlLPUw&feature=youtu.be",
  "https://youtu.be/xCe6NOBUut0",
  "https://www.youtube.com/watch?v=9Sz3zrn0g2w",
  "https://www.youtube.com/watch?v=ZjEVjAldMYo",
  "https://youtu.be/clLqaaGJJZ8",
  "https://www.youtube.com/watch?v=r7rUga7ToB0&feature=youtu.be",
  "https://youtu.be/Teg7PlJwRYQ",
  "https://www.youtube.com/watch?v=_aMPQhh9cd4",
  "https://www.youtube.com/watch?v=KUQeF_rWMjo",
  "https://youtu.be/JT436kMSlvk",
  "https://www.youtube.com/watch?v=0ILw2ECwpMQ",
  "https://www.youtube.com/watch?v=83qPBelgfa4",
  "https://www.youtube.com/watch?v=9iUdfLXBOMk",
  "https://youtu.be/xIxuxfsSGNI",
  "https://www.youtube.com/watch?v=fY6xuZR4V4o&feature=youtu.be",
  "https://youtu.be/ikKlG1f4mvY",
  "https://youtu.be/NOPIobI_0B8",
  "https://www.youtube.com/watch?v=yOgrUYM_JYw&feature=youtu.be",
  "https://youtu.be/DbHVR31x4YM",
  "https://www.youtube.com/watch?v=XbFWoxnm9Ok",
  "https://youtu.be/PWLwXJcTKHI",
  "https://youtu.be/6JyMHfi6ExE",
  "https://youtu.be/Mpa6VskX-Pk",
  "https://youtu.be/folyA_NRW70",
  "https://www.youtube.com/watch?v=NM_jnHy1N-w",
  "https://www.youtube.com/watch?v=ueO1mFzr92Y",
  "https://www.youtube.com/watch?v=_1g4qOJhThE&feature=youtu.be&t=20",
  "https://www.youtube.com/watch?v=0b6nx9godhQ",
  "https://youtu.be/l1Qerh-h5Vc",
  "https://www.youtube.com/watch?v=bGLjXZT5rhw&feature=youtu.be",
  "https://youtu.be/k978Dha9X_s",
  "https://youtu.be/m6pdg0hM-IA",
  "https://youtu.be/UVAmlSGH-MM",
  "https://www.youtube.com/watch?v=pofMiacntL0&feature=youtu.be",
  "https://youtu.be/Z6WeOi1bsPg",
  "https://youtu.be/01TDyMibPv8",
  "https://www.youtube.com/watch?v=gE4ODudenm0",
  "https://youtu.be/NSRYKl09kyU",
  "https://www.youtube.com/watch?v=B7_7H4q4dCw",
  "https://youtu.be/-FrpuPLYnvY",
  "https://www.youtube.com/watch?v=qiAdRcvUxm4&feature=youtu.be",
  "https://youtu.be/DTsJc6wEhJY",
  "https://www.youtube.com/watch?v=X-dZJ_ITCW4",
  "https://www.youtube.com/watch?v=l-TkqAhyArU&feature=youtu.be",
  "https://youtu.be/ugNn_ELV_xs",
  "https://www.youtube.com/watch?v=3QtRuP9bCDM",
  "https://www.youtube.com/watch?v=yw8oQOovrBw",
  "https://www.youtube.com/watch?v=2Zrir7kbkPQ",
  "https://youtu.be/2txqSDL6rsE",
  "https://www.youtube.com/watch?v=HD7ICLWWHbA",
  "https://www.youtube.com/watch?v=w-mtJYWXYjs",
  "https://www.youtube.com/watch?v=qEk1SYz_q6g",
  "https://youtu.be/2HAHl5lEu9M",
  "https://www.youtube.com/watch?v=eJo588b7Fio",
  "https://www.youtube.com/watch?v=k95EBVOCUJM",
  "https://www.youtube.com/watch?v=WXJoNZYek7E",
  "https://youtu.be/fAyQBszxoGM",
  "https://youtu.be/XdP6MCM3HRY",
  "https://www.youtube.com/watch?v=HJS0z91tGWI",
  "https://www.youtube.com/watch?v=5T76MPGRQDY&feature=youtu.be&t=17",
  "https://www.youtube.com/watch?v=W38PyIj_-1I",
  "https://www.youtube.com/watch?v=4ouXm5aPLzY",
  "https://youtu.be/YCFN4AXLHoU",
  "https://youtu.be/gByiybDHljI",
  "https://youtu.be/bS1bRVkc7J4",
  "https://youtu.be/q3T00-MvRck",
  "https://www.youtube.com/watch?v=kE70SaioSqY&feature=share",
  "https://www.youtube.com/watch?v=Th2IuyEVZwA&feature=youtu.be",
  "https://www.youtube.com/watch?v=8k_rZKFWPyc",
  "https://www.youtube.com/watch?v=LHNDLJxIFHA",
  "https://youtu.be/m8hshGvCN9s",
  "https://www.youtube.com/watch?v=M9mB0OGWkYE",
  "https://www.youtube.com/watch?v=ZjLTjLPUvpU",
  "https://youtu.be/wDl-0y8QtVM",
  "https://youtu.be/PWCcp6QXOmA",
  "https://www.youtube.com/watch?v=MkjGC8PISuI",
  "https://www.youtube.com/watch?v=q430dWj9sG4",
  "https://www.youtube.com/watch?v=LO91KOzKKG4",
  "https://youtu.be/YSNJJNAuNA0",
  "https://www.youtube.com/watch?v=kiX8JxSBC-s",
  "https://youtu.be/mQstvsOWcFk",
  "https://www.youtube.com/watch?v=phNHo6os0PY",
  "https://youtu.be/UjN7n7zyE1A",
  "https://youtu.be/jWx89KJWKRQ",
  "https://www.youtube.com/watch?v=THq4b1mnxcI",
  "https://youtu.be/AipI5H92_Cs",
  "https://youtu.be/UvEwhZO29ZE",
  "https://www.youtube.com/watch?v=Vd5YgpEqUYY",
  "https://youtu.be/XH7z72llkNU",
  "https://youtu.be/Q3SZ4lSvH9o",
  "https://www.youtube.com/watch?v=-IAju0mroT4",
  "https://www.youtube.com/watch?v=zRbly6aupi8",
  "https://www.youtube.com/watch?v=zzB76CbVDJU",
  "https://youtu.be/mlW-oR5M23c",
  "https://www.youtube.com/watch?v=T4Oasn5EUac",
  "https://www.youtube.com/watch?v=MPJ0AB12h1I",
  "https://youtu.be/TVL4e71R81Y",
  "https://www.youtube.com/watch?v=eosUJWPEkrI",
  "https://youtu.be/NLGPpq4QuGc",
  "https://m.youtube.com/watch?v=cjcNbgqXwms&t=31s",
  "https://www.youtube.com/watch?v=2KGw1ViQfDs",
  "https://youtu.be/yvMKSERPNFo",
  "https://www.youtube.com/watch?v=_y15eNZIyao",
  "https://www.youtube.com/watch?v=VlmDPC6ai4Y",
  "https://www.youtube.com/watch?v=yfZVvP6wNGU&t=20",
  "https://youtu.be/j7_4fqIdfpA",
  "https://youtu.be/n6P31U4Y-KM",
  "https://www.youtube.com/watch?v=Q8QlNuTUe4M",
  "https://www.youtube.com/watch?v=vGmLbkrz8ek",
  "https://www.youtube.com/watch?v=U9nkN-8pgts",
  "https://www.youtube.com/watch?v=yKOs6ed3hWk",
  "https://youtu.be/zA8EJwU_QM8",
  "https://youtu.be/tEo9AFHJfOI",
  "https://youtu.be/LNi9q3qK0os",
  "https://youtu.be/b3AqrUxaJz8",
  "https://youtu.be/VeQY4eB38WU",
  "https://youtu.be/Yq0ztaCwb-A?t=27s",
  "https://www.youtube.com/watch?v=eHmsblNnnGM",
  "https://www.youtube.com/watch?v=z1Txm96kNnM",
  "https://www.youtube.com/watch?v=MuCaiVvLsQw",
  "http://www.youtube.com/watch?v=NheFflM6yTM&t=0m2s",
  "https://youtu.be/VTyQ_52Ih-8",
  "https://youtu.be/tLUaycJFjWA",
  "https://youtu.be/ESn85RmTU-0",
  "https://www.youtube.com/watch?v=7JAC0YriB9I",
  "https://www.youtube.com/watch?v=R5Mxyy6zRpQ",
  "https://youtu.be/oVOzXjAu2ig",
  "https://youtu.be/tN4mu-NvyA4",
  "https://youtu.be/kWVFEVWJMz8",
  "https://www.youtube.com/watch?v=MGeKrPYOmuU",
  "https://youtu.be/X2CCCI0ekHI",
  "https://youtu.be/zrPhYIPFHrc?t=22",
  "https://youtu.be/r7JENgFD1uk",
  "https://m.youtube.com/watch?v=lhRe1kz1ZfM",
  "https://www.youtube.com/watch?v=W4SEqa774Ro",
  "https://www.youtube.com/watch?v=Bj7oJ11PjiQ",
  "https://youtu.be/Ha8XOntG0nw?t=31",
  "https://www.youtube.com/watch?v=3DU4dEJD8sk",
  "http://www.youtube.com/watch?v=z7nA2Tp5rVk",
  "https://youtu.be/wPBM_bsH38s",
  "https://youtube.com/watch?t=10s&v=Uuv6TVv0r44",
  "https://youtu.be/ip1XtsAdn2o",
  "https://youtu.be/ofuSDzTpkSg",
  "https://youtu.be/iI_oKy2hljw",
  "https://www.youtube.com/watch?v=n7k4AHLvYRE",
  "https://www.youtube.com/watch?v=m8YeyBH3FJU",
  "https://www.youtube.com/watch?v=ggM5EzZJ1ng",
  "https://youtu.be/OzTU6FnPa7w",
  "https://youtu.be/6GlsKKeaCNA",
  "https://www.youtube.com/watch?v=jEpxK9rGAL8",
  "https://m.youtube.com/watch?v=VyQEbLx6AEY",
  "https://youtu.be/4-BfsnTw1X4?t=187",
  "https://www.youtube.com/watch?v=hGD5FSeg4_o&feature=youtu.be",
  "https://youtu.be/0QgL03x1yDU",
  "https://youtu.be/yhEefkn2TPo",
  "https://www.youtube.com/watch?v=7WVcto-jSGQ",
  "https://www.youtube.com/watch?v=ajdYe2K1pMo",
  "https://youtu.be/X0mnsZh3m-I",
  "https://youtu.be/W-3Qbu_YqCQ",
  "https://m.youtube.com/watch?v=nFPh7fp8lV0",
  "https://youtu.be/xBTL_yStyJU",
  "https://www.youtube.com/watch?v=lFJMaM_jrDs",
  "https://youtu.be/H4u4Teyl3LY",
  "https://youtu.be/IfUGmMuQYk0?t=8s",
  "https://www.youtube.com/watch?v=dDeB8nZkHrk&t=0s",
  "https://www.youtube.com/watch?v=kyKEeTLb9xw",
  "https://www.youtube.com/watch?v=SoEiSDywJw8",
  "https://youtu.be/quTQRgg9vWY",
  "https://youtu.be/TGleTynEtOI",
  "https://youtu.be/HZOQtcWXf9M",
  "https://youtu.be/GLkr4COqAo8?t=30",
  "https://www.youtube.com/watch?v=V-V08OfaObo&feature=youtu.be",
  "https://www.youtube.com/embed/mbSFSvmCbb4?&end=30",
  "https://youtu.be/65C2GmyUAlE",
  "https://youtu.be/QGvpciznxdA",
  "https://youtu.be/koV3cFi5n48",
  "https://www.youtube.com/watch?v=MztdLHkWbeM",
  "https://www.youtube.com/watch?v=twOACWxvX3E&feature=youtu.be",
  "https://youtu.be/Cs1SkB_8PEo",
  "https://www.youtube.com/watch?v=diztkTtUwr8",
  "https://youtu.be/xc917wPX1x8",
  "https://youtu.be/LIlTWWIHeoM",
  "https://www.youtube.com/watch?v=-_H9o2_uyLw",
  "https://www.youtube.com/watch?v=r3DR0fGl9MU",
  "https://www.youtube.com/watch?v=Tik0MaHcxTc",
  "https://www.youtube.com/watch?v=90AiXO1pAiA",
  "https://youtu.be/bK3a2RZFIRw",
  "https://www.youtube.com/watch?v=uSI6R7Bn4wU",
  "https://www.youtube.com/watch?v=QB_ZxGY8x7M&feature=youtu.be",
  "https://www.youtube.com/watch?v=38mdvnMGRmg",
  "https://www.youtube.com/watch?v=fDCIgCDqvMU",
  "https://www.youtube.com/watch?v=ENA7XHlgD2k",
  "https://www.youtube.com/watch?v=w0gPHRpRhQc",
  "https://youtu.be/YWQ4ClTmRnQ?t=9s",
  "https://youtu.be/S_hfAgpINhA",
  "https://youtu.be/oGlaYHT19sc",
  "https://youtu.be/vwZQ1u0Zlps",
  "https://www.youtube.com/watch?v=Xb2688Y0muw",
  "https://www.youtube.com/watch?v=jwCMMjh-HpI",
  "https://www.youtube.com/watch?v=2buteHswvBA",
  "https://www.youtube.com/watch?v=UdxqgFXrp84&feature=youtu.be",
  "https://www.youtube.com/watch?v=AuKkjMYJfFA&feature=youtu.be",
  "https://youtu.be/q1h3bPm0m0w",
  "https://www.youtube.com/watch?v=VUzoJrfJQBw",
  "https://www.youtube.com/watch?v=QbPj5vfprIQ",
  "https://www.youtube.com/watch?v=lKMDvCf1IUE&feature=youtu.be",
  "https://youtu.be/vUmtbnqxcgg",
  "https://www.youtube.com/watch?v=arnWU1sWqKw",
  "https://www.youtube.com/watch?v=cm3m8lurdiU",
  "https://www.youtube.com/watch?v=3mBjn7nKrLc&feature=youtu.be",
  "https://youtu.be/5e5fPk2cpA4",
  "https://youtu.be/GnicntHENN8?t=25s",
  "https://www.youtube.com/embed/oSQ9eGhb34M?end=30",
  "https://www.youtube.com/watch?v=SKbhhaH0TwY",
  "https://m.youtube.com/watch?v=rUEc9E_U5Ck",
  "https://www.youtube.com/watch?v=KsfpChLHSqQ",
  "https://youtu.be/pTTMzJpofLg",
  "https://youtu.be/Vu-dEqL5AVw",
  "https://www.youtube.com/watch?v=z7EB8kSU7-o",
  "https://www.youtube.com/watch?v=TFp8gUu1RRw",
  "https://www.youtube.com/watch?v=vRxaHGabtds",
  "https://youtu.be/btm140RA-bY",
  "https://youtu.be/2ga5Hior6rI",
  "https://youtu.be/TpNyRmvOv7k",
  "https://youtu.be/Y-At6LrpsGQ",
  "https://www.youtube.com/watch?v=5q7H6EoPzHQ",
  "https://youtu.be/IZwwc8e4CAk",
  "https://www.youtube.com/watch?v=V0TlKG0C0Y0",
  "https://youtu.be/tC5QnC1zyd8?t=13",
  "https://www.youtube.com/watch?v=KYjPasIJzxo",
  "https://www.youtube.com/watch?v=tkGxcWgAlrY",
  "https://youtu.be/fBOB_y_7r4g?t=12s",
  "https://m.youtube.com/watch?v=29s6fS7Y5dY",
  "https://www.youtube.com/watch?v=D5_zQSg-tk4",
  "https://youtu.be/GkzipbQEoaw?t=559",
  "https://youtu.be/UM8pwnmcLb0",
  "https://www.youtube.com/watch?v=82l8mDoEU3c",
  "https://www.youtube.com/watch?v=jWFWazj7Ud8",
  "https://www.youtube.com/watch?v=BU0icUCMI_U",
  "https://youtu.be/xV7nHX2RLjQ",
  "https://www.youtube.com/watch?v=vpbGgysqE4c",
  "https://www.youtube.com/watch?v=7FjjnWfcJ-k",
  "https://www.youtube.com/watch?v=MjF6AkrXods",
  "https://www.youtube.com/watch?v=xdc-ucb7kZc&t=",
  "https://www.youtube.com/watch?v=gVQniXcEgqg",
  "https://youtu.be/U8oA0b2KrxY",
  "https://www.youtube.com/watch?v=1GOQ9wSvV7I",
  "https://www.youtube.com/watch?v=_JkwZUk3Kng",
  "https://youtu.be/sxEjHciJv8g",
  "https://www.youtube.com/watch?time_continue=3&v=Vhzzt4SK7zw",
  "https://youtu.be/J9TR38UVPh0",
  "https://youtu.be/0DmtBQkKH3w?t=2s",
  "https://youtu.be/bwDrHqNZ9lo?t=19s",
  "https://www.youtube.com/watch?v=7pDBS0dMb_A",
  "https://www.youtube.com/watch?v=Rw8gE3lnpLQ",
  "https://www.youtube.com/watch?v=YQfa5Yoko1Q",
  "https://www.youtube.com/watch?v=rBeNO0GCSfU",
  "https://www.youtube.com/watch?v=5JJBDwA6dpw",
  "https://youtu.be/cc6xpU6P9Zs",
  "https://youtu.be/6Iiti-5IlkM",
  "https://youtu.be/ifpGI9Rl84o",
  "https://youtu.be/gXqNLx0kTaY",
  "https://www.youtube.com/watch?v=DRl1h-g06vQ",
  "https://www.youtube.com/watch?v=WzBEoXWMN2g",
  "https://youtu.be/dWAEQ_yM1Ek",
  "https://www.youtube.com/watch?v=N2GDiP1MfPk&t=0s",
  "https://www.youtube.com/watch?v=MA8rhf2KVzs",
  "https://www.youtube.com/watch?v=4iTbUGItU0s",
  "https://www.youtube.com/watch?v=W1U29FZIZaM&feature=youtu.be",
  "https://www.youtube.com/watch?v=FYujZlX22Rc",
  "https://www.youtube.com/watch?v=Mb1GfP5Rwys",
  "https://www.youtube.com/watch?v=ccZkcFEyUyc",
  "https://www.youtube.com/watch?v=cHLNs0VMnzM",
  "https://youtu.be/VhGG_EW1gNg",
  "https://www.youtube.com/watch?v=P12wk424ttA&t=0s",
  "https://www.youtube.com/watch?v=kcoWQdc66KE",
  "https://www.youtube.com/watch?v=QTYID4lXhM0",
  "https://www.youtube.com/watch?v=SnljnsFMK20&feature=youtu.be",
  "https://www.youtube.com/watch?v=m4wvd6JiS4I",
  "https://www.youtube.com/watch?v=DjpcID1lU1Y&feature=youtu.be&t=0m31s",
  "https://youtu.be/4JrZrrDFiMw",
  "https://www.youtube.com/watch?v=BuyffsTalMI",
  "https://www.youtube.com/watch?v=HiN6siMYy1M",
  "https://www.youtube.com/watch?v=A_pIPTih5iM",
  "https://youtu.be/eSFXs-iotdM",
  "https://www.youtube.com/watch?v=PjhXetFSJ-I&ab_channel=MrSpectacals",
  "https://www.youtube.com/watch?v=v3E3Y3LW0X4",
  "https://youtu.be/DmUZ9iHKrlA",
  "https://m.youtube.com/watch?v=WCoLAYBeVRE",
  "https://www.youtube.com/watch?v=3AyvNEO0Vw4",
  "https://youtu.be/uXOo8LSmYJ4?t=1",
  "https://www.youtube.com/watch?v=wfb2simaHGU&feature=youtu.be",
  "https://youtu.be/fodScGd74ro",
  "https://www.youtube.com/watch?v=dR-FXOLPlCs",
  "https://www.youtube.com/watch?v=ww4cmDuOMKc",
  "https://youtu.be/7-1xSLhlYnE",
  "https://youtu.be/q80rBiNd_GE",
  "https://www.youtube.com/watch?v=Okk3af9SRgk",
  "https://youtu.be/t_rDbz6IPhA",
  "https://youtu.be/nZHxy9p6PTU",
  "https://www.youtube.com/watch?v=pfzM7RSZKhQ",
  "https://youtu.be/WGNuDe3OwFY",
  "https://www.youtube.com/watch?v=nvtBBSOe3tw",
  "https://youtu.be/835d1DjBJLs",
  "https://youtu.be/qZufUeh-mKM",
  "https://www.youtube.com/watch?v=QK-1AEsjR7c",
  "https://youtu.be/Ex8vBCvbmkg",
  "https://youtu.be/1_V1afGQj4M",
  "https://www.youtube.com/watch?v=QpexhpQxiLI",
  "https://www.youtube.com/watch?v=K6NVFGYiiRM&feature=youtu.be",
  "https://youtu.be/B8Qh5CqhE78",
  "https://youtu.be/-QPJoRWz8Sc",
  "https://youtu.be/t5hDdcjfncg",
  "https://www.youtube.com/watch?v=LG6UWq46AB4",
  "https://www.youtube.com/watch?v=ZKkkyd7C_Ew",
  "https://youtu.be/o4KabrWmKus",
  "https://youtu.be/GpD4-hh3gzg",
  "https://www.youtube.com/watch?v=jGMPzJJvZO8",
  "https://www.youtube.com/embed/SzWZtzpsouk?end=30",
  "https://www.youtube.com/watch?v=uLa1rM1GRdM",
  "https://youtu.be/hvoagWSOw_Y?t=4",
  "https://www.youtube.com/watch?v=e8KB4l8WT0U",
  "https://youtu.be/6BeIufS3fZ0?t=45",
  "https://youtu.be/Y19wIg-gPrc",
  "https://www.youtube.com/watch?v=lLN6qj42t90",
  "https://www.youtube.com/watch?v=-GLu20Cf4eA",
  "https://youtu.be/fA3M7fjr60Y?t=226",
  "https://www.youtube.com/watch?v=oPLUm29iBmA",
  "https://youtu.be/9ps7XTZxRjg",
  "https://www.youtube.com/watch?v=xS-l2PxS2QM&feature=youtu.be&t=81",
  "https://www.youtube.com/watch?v=jO2pKgO1HvQ",
  "https://youtu.be/DJp_Q-2hohA",
  "https://youtu.be/dSNZAS5SqZI",
  "https://youtu.be/Fyh8GKhgVxI",
  "https://www.youtube.com/watch?v=ncrXieoHSWY",
  "https://youtu.be/mA9LNYwGH6s",
  "https://youtu.be/A6Pxa9pikYc",
  "https://youtu.be/7zyBJoZikV0",
  "https://youtu.be/NYVPCn6Rd6Y?t=1",
  "https://www.youtube.com/watch?v=n9ib8025Xfk",
  "https://www.youtube.com/watch?v=NqBUaiDnUws",
  "https://www.youtube.com/watch?v=H-ohKhxL2Uk",
  "https://www.youtube.com/watch?v=BDMvKaR3hKI",
  "https://www.youtube.com/watch?v=IO0dJ05mgqE",
  "https://www.youtube.com/watch?v=hAmb7Td-lEw&start=6&end=21",
  "https://www.youtube.com/watch?v=plQAy918920",
  "https://www.youtube.com/watch?v=Gfcfe0UAf9M",
  "https://youtu.be/j0bPyQxyFZU",
  "https://youtu.be/EBIsZlV1jHk",
  "https://youtu.be/luDxN1Pz6G8",
  "https://www.youtube.com/watch?v=3jr0QKaSv9Q",
  "https://www.youtube.com/watch?v=PnBBNgshgD4&feature=youtu.be",
  "https://youtu.be/Yj5ec0pS1XI",
  "https://www.youtube.com/watch?v=wBybVfTWLv8&feature=youtu.be",
  "https://www.youtube.com/watch?v=IXMS0KIYoMI",
  "https://www.youtube.com/embed/i3pfsCS7fWI?start=16&end=46",
  "https://www.youtube.com/watch?v=BJ3BoZ3UW_0&t=0s",
  "https://www.youtube.com/embed/DwUkdUSG7v4?end=30",
  "https://www.youtube.com/watch?v=X_JYkP6JotQ",
  "https://youtu.be/ObiNO0O-qY8?t=9",
  "https://youtu.be/3QcxVzXP5Ho",
  "https://youtu.be/1O0grZtRwdk",
  "https://youtu.be/18Tat4rdZ40",
  "https://www.youtube.com/watch?v=Y1cdtuTXfFs",
  "https://youtu.be/LfHd7EHlQ-0",
  "https://www.youtube.com/watch?v=P1iqLU2KWJk",
  "https://www.youtube.com/watch?v=HSO9k_x7RMA&feature=youtu.be",
  "https://www.youtube.com/watch?v=5S4Ss5bK-ws",
  "https://www.youtube.com/watch?v=N_QbPs2UMDE",
  "https://www.youtube.com/watch?v=9B7J8KZ06tI",
  "https://youtu.be/YfrvJnxGzU0",
  "https://youtu.be/6G8YCM0pGfU",
  "https://youtu.be/-0xvSx5s76M",
  "https://www.youtube.com/watch?v=WDvOgzKZ3EI",
  "https://youtu.be/r8jrzI8mEUQ",
  "https://www.youtube.com/watch?v=kqgvSI3zzVU&t=0s",
  "https://www.youtube.com/watch?v=yESC9VWLKiE",
  "https://www.youtube.com/watch?v=Z-PtX62xlgU",
  "https://youtu.be/pqjVdPtB9lU",
  "https://youtu.be/ygt-5ef4vzI",
  "https://www.youtube.com/watch?v=gfb7qeVKGrI",
  "https://www.youtube.com/watch?v=JDXOaaqthVc",
  "https://www.youtube.com/watch?v=hiAVOegXmKQ",
  "https://www.youtube.com/watch?v=e13k7kjkl0Q&feature=youtu.be",
  "https://www.youtube.com/watch?v=Cc-gx1bS8yM",
  "https://youtu.be/CEhk5N32Tec?t=41",
  "https://www.youtube.com/watch?v=eFYV3_XBXWs",
  "https://www.youtube.com/watch?v=SZ2L-R8NgrA",
  "https://www.youtube.com/watch?v=7KcPNiworbo",
  "https://www.youtube.com/watch?v=O-Jvx-olKtU",
  "https://youtu.be/8K2wTF7pZzI",
  "https://youtu.be/PD2iaqfLvs8",
  "https://youtu.be/wTnUYuuK5wI",
  "https://www.youtube.com/watch?v=3Qsk3YISy3k",
  "https://www.youtube.com/watch?v=ieG-ACMBOLk",
  "https://www.youtube.com/watch?v=uThq0En9EiM",
  "https://youtu.be/sERQ3F3iNmU",
  "https://www.youtube.com/embed/qqXw84jYYg0?start=5&end=35",
  "https://youtu.be/qsbL04pUGdQ",
  "https://youtu.be/tZxeNKtVDPs",
  "https://www.youtube.com/watch?v=fsiSss77tvc&feature=youtu.be",
  "https://www.youtube.com/watch?v=ssBFgDOlhM8",
  "https://www.youtube.com/watch?v=JDOhcQWMmN8",
  "https://www.youtube.com/watch?v=wVXifGJcI9A",
  "https://www.youtube.com/watch?v=PQE73eWRLLU",
  "https://youtu.be/uUPHlAbAf2I?t=21",
  "https://www.youtube.com/watch?v=yiFNrO33bSo",
  "https://www.youtube.com/watch?v=EwArMFCLmZY",
  "https://youtu.be/p8hGZoXWR70?t=1s",
  "https://www.youtube.com/watch?v=GgWsADYJdpM",
  "https://www.youtube.com/watch?v=rQ6IYR9Q4fQ",
  "https://www.youtube.com/watch?v=d4puxndUA1g",
  "https://www.youtube.com/watch?v=_DJ7gXorYrQ",
  "https://www.youtube.com/watch?v=609HhzQ6zfU",
  "https://www.youtube.com/watch?v=pFPd0pIjrOI",
  "https://www.youtube.com/watch?v=Dtvc73-kAF0",
  "https://www.youtube.com/watch?v=izjBvGeKSyY",
  "https://www.youtube.com/watch?v=jLxmgFnZoK0",
  "https://www.youtube.com/watch?v=v0w0BMrvH-8",
  "https://www.youtube.com/watch?v=Vfp6kyGz2wE",
  "https://youtu.be/cA9WYOU8VWg",
  "https://www.youtube.com/watch?v=_hLkzjM_abc&feature=youtu.be",
  "https://www.youtube.com/watch?v=0tdyU_gW6WE",
  "https://www.youtube.com/watch?v=CEhk5N32Tec",
  "https://youtu.be/6BeIufS3fZ0?t=53",
  "https://www.youtube.com/watch?v=JU7tzofTJIE",
  "https://www.youtube.com/watch?v=t68REu0HwBw",
  "https://www.youtube.com/watch?v=KQ5bUkk6iW0",
  "http://www.youtube.com/watch?v=UgMYdsFV-hk&t=0m37s",
  "https://www.youtube.com/watch?v=EPS2C3SYLsY",
  "https://www.youtube.com/watch?v=LKxpwlKRQ2U&feature=youtu.be",
  "https://www.youtube.com/watch?v=vQ649wd0Fw4",
  "https://www.youtube.com/watch?v=IC-wDpwzEt4",
  "https://www.youtube.com/watch?v=djz84fkt7r0",
  "https://youtu.be/8umgKkXrLdw",
  "https://youtu.be/bfODaLgD68c",
  "https://www.youtube.com/watch?v=uUx9E95saEM",
  "https://youtu.be/VRoYCpjMPyk?t=63",
  "https://www.youtube.com/watch?v=Dhs1O5cR2PU",
  "https://www.youtube.com/watch?v=wg0G4f7KR3M",
  "https://www.youtube.com/watch?v=o5tSFEAGxZ4",
  "https://youtu.be/WqSsew3ZWws",
  "https://youtu.be/yFtOg2F6kvk",
  "https://youtu.be/zE-qV-AnQ_s",
  "https://youtu.be/vsdJqZJGsiE",
  "https://www.youtube.com/watch?v=v8kbydwjR0I",
  "https://www.youtube.com/watch?v=I0zkaySugUU",
  "https://www.youtube.com/watch?v=4tDrVZZxh6o&feature=youtu.be",
  "https://youtu.be/5R24CTGLiC8",
  "https://youtu.be/katiM70GZp8",
  "https://youtu.be/ahUfsSQCP3Q",
  "https://www.youtube.com/watch?v=Ia8hIvIhJ18",
  "https://youtu.be/6dckooBjAvg",
  "https://www.youtube.com/watch?v=-SNIlKsPiwU",
  "https://youtu.be/6kYco2Zt-cM",
  "https://www.youtube.com/watch?v=QEJcTWnLJF4",
  "https://www.youtube.com/watch?v=-iLnGrdBDM0",
  "https://www.youtube.com/watch?v=RQjzjkUyzvU",
  "https://youtu.be/r4cf2NJTfIA",
  "https://www.youtube.com/attribution_link?a=YIG_8qLayLE&u=%2Fwatch%3Fv%3DndBoCSlHyKc%26feature%3Dshare",
  "https://www.youtube.com/watch?v=ursltNhpNpc",
  "https://www.youtube.com/watch?v=xbk3OdYBLHA",
  "https://www.youtube.com/watch?v=tzvT7Vo9OUM",
  "https://www.youtube.com/watch?v=KsBjVvxBj84",
  "https://www.youtube.com/watch?v=0d4qzcueL5A",
  "https://www.youtube.com/watch?v=jJ6GPabvSfY",
  "https://youtu.be/VeTMg7H7r-E",
  "https://youtu.be/oHXxNGy1XM4?t=3",
  "https://www.youtube.com/watch?v=sCeMnquz5z0&feature=youtu.be",
  "https://www.youtube.com/watch?v=GiwdYok2KhM",
  "https://youtu.be/F-U2ypPYA8Q",
  "https://www.youtube.com/watch?v=atM2-Wt91Qg",
  "https://youtu.be/LbR9WO5BBI8",
  "https://www.youtube.com/watch?v=KTOWBj70BPU",
  "https://www.youtube.com/watch?v=WL8FQ60PFvs",
  "https://youtu.be/856B1Ng29J0",
  "https://www.youtube.com/embed/MqkSLTI3QTI?start=36&end=60",
  "https://www.youtube.com/watch?v=bBG1EoezERw",
  "https://www.youtube.com/watch?v=X1lxdso8mro",
  "https://www.youtube.com/watch?v=sPzJjNQaYEA&feature=youtu.be",
  "https://www.youtube.com/watch?v=cx-x8CPKscI",
  "https://youtu.be/gQa_ekXFQIA",
  "https://youtu.be/PFr_eSFq1qE",
  "https://www.youtube.com/watch?v=5M9Z4C_SEu8&feature=youtu.be",
  "https://www.youtube.com/watch?v=QqvkzA7_UMQ",
  "https://www.youtube.com/watch?v=z136rfKxXas",
  "https://youtu.be/u6v_idUQwJM",
  "https://www.youtube.com/watch?v=lag1bXylX00",
  "https://youtu.be/fz5isrNeprk",
  "https://www.youtube.com/watch?v=NPxZjZks7bw",
  "https://youtu.be/Mfe3y5OVnTU",
  "https://www.youtube.com/watch?v=Fsx7X7I1ikI",
  "https://www.youtube.com/watch?v=NVoOf2LXhRA&feature=youtu.be",
  "https://www.youtube.com/watch?v=rATDRNsTtUE",
  "https://www.youtube.com/watch?v=GL6nLK5LUog",
  "https://www.youtube.com/watch?v=ZijVdAp3PVA&feature=youtu.be",
  "https://www.youtube.com/watch?v=2epVL-r9nIU",
  "https://youtu.be/5NeR-bT3uv0?t=82",
  "https://www.youtube.com/watch?v=oYmqJl4MoNI&app=desktop",
  "https://youtu.be/bUFLmI2M2BA?t=5",
  "https://www.youtube.com/watch?v=jqW7ab_U87I",
  "https://youtu.be/26ZFJrqzz3k",
  "https://www.youtube.com/watch?v=1u4_hEmrQzE",
  "https://youtu.be/tkI2SxvaVTU",
  "https://youtu.be/r7GSLnP3F4M",
  "https://www.youtube.com/watch?v=UFCGlClSpNI",
  "https://www.youtube.com/watch?v=GYN6VYhhq7U",
  "https://www.youtube.com/watch?v=PI-nzF0Yyc8",
  "https://youtu.be/_lgfnjMzLDY",
  "https://www.youtube.com/watch?v=IqsLSwkenJo&feature=youtu.be",
  "https://youtu.be/i5DvIv49VWo",
  "https://youtu.be/fMfapDlm9UY",
  "https://www.youtube.com/watch?v=hvo-5TLG6CA",
  "https://www.youtube.com/watch?v=SNTGRhVgNrU",
  "https://www.youtube.com/watch?v=JtPKWj1FASA",
  "https://youtu.be/oTMWnv6c4BE",
  "https://www.youtube.com/watch?v=XPZq3B7DGi8",
  "https://www.youtube.com/watch?v=spgGBBviLnQ",
  "https://youtu.be/BN4x7nA-lSs",
  "https://www.youtube.com/watch?v=CWXu6hNvTzI",
  "https://youtu.be/6ibrkv1ot0c",
  "https://www.youtube.com/watch?v=qSB-cuUKS_0",
  "https://www.youtube.com/watch?v=4DSQGOEKEMc",
  "https://www.youtube.com/watch?v=1Av0li14r1E",
  "https://www.youtube.com/watch?v=vXkGZawnjyc",
  "https://www.youtube.com/watch?v=dwPp6bMrYuY",
  "https://www.youtube.com/watch?v=9gFflgBuUiE&feature=share",
  "https://youtube.com/watch?v=tS8vJyD06OY",
  "https://youtu.be/ca4X22Jgirk",
  "https://www.youtube.com/watch?v=CL106CT6CiA",
  "https://youtu.be/tRCDIEOU310",
  "https://www.youtube.com/watch?v=dw-8k8uOjes",
  "https://youtu.be/b2kCTSLJqHA",
  "https://www.youtube.com/watch?v=hqyAwi7fVAc",
  "https://www.youtube.com/watch?v=ub7vLgT7ZRo",
  "https://youtube.com/watch?v=6SEBBuZYMF8",
  "https://www.youtube.com/watch?v=bGLjXZT5rhw",
  "https://youtu.be/I7HaKm4KBqk",
  "https://www.youtube.com/watch?v=B6XbW8a1Dso",
  "https://youtu.be/9EqaUXfoI7w",
  "https://youtu.be/SXkSAEE6amU",
  "https://www.youtube.com/watch?v=Njs4Lqx337c",
  "https://www.youtube.com/watch?v=i3YWVOBJ87E",
  "https://youtube.com/watch?v=9-GRzu6zbS0",
  "https://www.youtube.com/watch?v=omHwoN95se0",
  "https://www.youtube.com/watch?v=fFOx_LMTDiE",
  "https://www.youtube.com/watch?v=aaPZbcEtPhA",
  "https://youtu.be/42OleX0HR4E",
  "https://www.youtube.com/watch?v=swr719Qc2Yk",
  "https://www.youtube.com/watch?v=3w-C0-zVaW8&feature=youtu.be&t=105",
  "https://youtu.be/U6pbkmyAt1g",
  "https://www.youtube.com/watch?v=0REldh4MUDg",
  "https://www.youtube.com/watch?v=5XEVIOsTV08",
  "https://www.youtube.com/watch?v=eSpGczTZIJc",
  "https://youtu.be/A0269LSqhSg",
  "https://www.youtube.com/watch?v=-EzhAO6BZ7s",
  "https://youtu.be/ylSTqHwaSIs",
  "https://www.youtube.com/watch?v=zXsXSMcwl-k&feature=youtu.be",
  "https://www.youtube.com/watch?v=3UlWUIhIJrs",
  "https://www.youtube.com/watch?v=G6frF4uDjwk",
  "https://www.youtube.com/watch?v=uojxYVuGMwM",
  "https://www.youtube.com/watch?v=5FRhK-KuUy8",
  "https://youtu.be/-8asbYbWRl4",
  "https://youtu.be/g2TjRP4n5Lg",
  "https://youtu.be/PynP6zvHOUU",
  "https://youtu.be/ON9TbMy23Uw",
  "https://youtu.be/TTne_5JN1mY",
  "https://youtu.be/2ZjkHhVwcVY?t=68",
  "https://youtu.be/NOIMGo3T7pk",
  "https://www.youtube.com/watch?v=0NNJLBn_03o",
  "https://www.youtube.com/watch?v=fhmeYoJZeOw",
  "https://www.youtube.com/embed/ZriIkj6IgFw?start=0&end=24",
  "https://www.youtube.com/watch?v=OqLVc7PbdoY",
  "https://youtu.be/2IkppQU9iIM",
  "https://www.youtube.com/watch?v=Dh_8woW-drY",
  "https://www.youtube.com/watch?v=X1vmKz99t_A",
  "https://youtu.be/1MtCdWBTnSg",
  "https://www.youtube.com/watch?v=Mnv116JZO_8",
  "https://www.youtube.com/watch?v=NgXI48tDiWc",
  "https://youtu.be/42l4snYh_yw",
  "https://youtu.be/unoewKuD9No",
  "https://www.youtube.com/watch?v=yp172JaORoI",
  "https://www.youtube.com/watch?v=R2zC3dKQ42Y",
  "https://youtu.be/norUYzDDwQw",
  "https://youtu.be/aamJdS0hjQI?t=1",
  "https://youtu.be/FCxfjpm-4nc",
  "https://www.youtube.com/watch?v=1i8AHCZrJUk&feature=",
  "https://m.youtube.com/watch?v=do_kI97OTHs&feature=youtu.be",
  "https://youtu.be/GM_JISL7PbY",
  "https://www.youtube.com/watch?v=zDZaTCum2A0",
  "https://www.youtube.com/watch?v=4X7q87RDSHI",
  "https://youtu.be/Vnigaf-oQbo",
  "https://youtu.be/oCv6fKoOujI",
  "https://www.youtube.com/watch?v=ixWybGQnPHQ&feature=youtu.be",
  "https://youtu.be/WMLMgpM2wf8",
  "https://m.youtube.com/watch?v=SLY839wglJY",
  "https://youtu.be/TV4eUy5x05Q",
  "https://www.youtube.com/watch?v=Qqt-D7RPQyw",
  "https://youtu.be/AHsIJ-QAJc8",
  "https://www.youtube.com/watch?v=5QYpD428hAQ&feature=youtu.be&t=726",
  "https://youtu.be/wgwvigYthNg",
  "https://youtu.be/O4UQxYiLYj8",
  "https://youtu.be/AJ-fq_rzfSM",
  "https://youtu.be/amSVJP5TgMQ",
  "https://youtu.be/kgTy4DjbswA",
  "https://www.youtube.com/watch?v=oYd_pph6RnI",
  "https://youtu.be/QBSt9Iyqhag",
  "https://www.youtube.com/watch?v=q2LughbIfyE",
  "https://www.youtube.com/watch?v=2okrgAMdHok",
  "https://www.youtube.com/watch?v=44BBBFBWeLc&feature=youtu.be",
  "https://www.youtube.com/watch?v=2Hqz78HwlLY",
  "https://www.youtube.com/watch?v=EYR_WDDbz3k",
  "https://youtu.be/VmEUs6nZt_Y",
  "https://youtu.be/jfGIgy43vxA",
  "https://youtu.be/LE0fzQGo9l0",
  "https://youtu.be/jsCCzkaB9pY?t=107",
  "https://www.youtube.com/watch?v=UiJVhv5XEdM",
  "https://youtu.be/T5aAoStjLbE",
  "https://www.youtube.com/watch?v=n7ARoM3gzFA",
  "https://www.youtube.com/watch?v=QAoONl2P8fw",
  "https://youtu.be/4SoIqZF57qE",
  "https://www.youtube.com/watch?v=2bp6Xuui95M&fbclid=IwAR0Tyr8P9SYvmPELTfe6baf0FOLxSoscyVfii9ere2aKyg5u_Xo2IhehEho&t=0s",
  "https://youtu.be/jnSGNMtikDk",
  "https://www.youtube.com/watch?v=0vbcPfi2Yrc&start=1",
  "https://www.youtube.com/watch?v=y4T9zbNMAP0&t",
  "https://youtu.be/CkjA-gILARI",
  "https://youtu.be/KwXeFDroKyI",
  "https://www.youtube.com/watch?v=JSFFPPFa9oc",
  "https://www.youtube.com/watch?v=_mN09BgMsW8",
  "https://www.youtube.com/watch?v=xrqivcSQBok",
  "https://www.youtube.com/watch?v=HERbcKjRZzU",
  "https://youtu.be/GSgYTV-Tks0?t=32",
  "https://www.youtube.com/watch?v=XFyCEMtjerA",
  "https://www.youtube.com/watch?v=De8sNVhJ5SE",
  "https://youtu.be/hC73ic8779s",
  "https://youtu.be/s1u8raxs5yo",
  "https://www.youtube.com/watch?v=lnaTIUURnj4",
  "https://www.youtube.com/watch?v=zf4EV8WwUwo",
  "https://youtu.be/bnwuvQZ_CCE",
  "https://youtu.be/9P6qYMalkaI",
  "https://youtu.be/YSWKtuaV2fY",
  "https://youtu.be/ZmEiE5t16HY",
  "https://youtu.be/xg8qDPGeFMU",
  "https://youtu.be/MtWhspBmXgM",
  "https://www.youtube.com/watch?v=9i-l3R11lNo",
  "https://youtu.be/zI26Hm13wMg",
  "https://www.youtube.com/watch?v=XuS6tsA_yfA",
  "https://www.youtube.com/watch?v=HAkBOvide1A",
  "https://www.youtube.com/watch?v=hezsoNLNwhM",
  "https://www.youtube.com/watch?v=V2mzLuP_KkE",
  "https://www.youtube.com/watch?v=mKHK55eALtM",
  "https://www.youtube.com/watch?v=6_NeqMAAsBk",
  "https://youtu.be/UOQXBwBWzQ0",
  "https://www.youtube.com/watch?v=nrpIj8xCzDQ&feature=youtu.be",
  "https://www.youtube.com/watch?v=zo7fgswQPJ8",
  "https://www.youtube.com/watch?v=2kMCxy3c5p8",
  "https://www.youtube.com/watch?time_continue=19&v=QmKhGqWcJGY",
  "https://www.youtube.com/watch?v=JWAKUg6JDQw",
  "https://www.youtube.com/watch?v=A-K9DCPpmyE",
  "http://www.youtube.com/watch?v=IyYZVRDjWOU&t=0m3s",
  "https://youtu.be/Xh18Cm5Rm90",
  "https://www.youtube.com/watch?v=ahoHDU0T44I",
  "https://www.youtube.com/watch?v=yLFqJOzcPRU",
  "https://www.youtube.com/watch?v=8JrAcSPCZWg",
  "https://youtu.be/3qIfvNrZUYQ?t=82",
  "https://youtu.be/BQ1umu78KSU?t=59",
  "https://www.youtube.com/watch?v=y6sdfZXSHF4",
  "https://www.youtube.com/watch?v=iCiGVgCIzGs",
  "https://www.youtube.com/watch?v=V4C4yoMPp0o&feature=youtu.be&t=105",
  "https://www.youtube.com/watch?v=EA7UQOYskas",
  "https://www.youtube.com/watch?v=hS4IRJ-Xa8c&t=00s",
  "https://www.youtube.com/watch?v=SdreWdYSQz4&t=0s",
  "https://www.youtube.com/watch?v=MBl4k0daoP4&t=00s",
  "https://www.youtube.com/watch?v=BCQM4efUUUI",
  "https://www.youtube.com/watch?v=oQaasxr3zWY",
  "https://youtu.be/cbk-3pwLqqk",
  "https://www.youtube.com/watch?v=mfshAzV0FN4",
  "https://www.youtube.com/watch?v=7Svf76f4ZfU",
  "https://youtu.be/1hgKgCFuLCI",
  "https://youtu.be/ktkCahg2GBA",
  "https://www.youtube.com/watch?v=fL6uYfOBx4E",
  "https://youtu.be/fe3na9umxDA",
  "https://www.youtube.com/watch?v=x7pb5oj0KQw&feature=youtu.be&t=9",
  "https://www.youtube.com/watch?v=FvdHLm1zoio",
  "https://youtu.be/QFxjM-6AStA",
  "https://youtu.be/3ReOsv31rwU",
  "https://youtu.be/Sp9xfM6SSTI",
  "https://www.youtube.com/watch?v=8Tm9YJ2qUW8",
  "https://youtu.be/Nm8np9xS1GM",
  "https://www.youtube.com/watch?v=x2S9CGiRXcI",
  "https://www.youtube.com/watch?v=L0spg646LBI&feature=youtu.be",
  "https://youtu.be/U-rk-4qYmR4",
  "https://youtu.be/14tpZ3tlQVA",
  "https://www.youtube.com/watch?v=cG4vS3v1nBo",
  "https://www.youtube.com/watch?v=r2pLsimZ3BM&ab_channel=Kapt%27nKrunch",
  "https://youtu.be/XYl8P78Bt3Y",
  "https://www.youtube.com/watch?v=7Uzaz6qymXc",
  "https://www.youtube.com/watch?v=MnkRfTSD8Mg&feature=youtu.be",
  "https://www.youtube.com/watch?v=2Hcznyxmb9w",
  "https://www.youtube.com/watch?v=Y6YvCfmsAUA",
  "https://www.youtube.com/watch?v=j58V2vC9EPc",
  "https://youtu.be/-w17mxQTXRg",
  "https://www.youtube.com/watch?v=UKdT3kpDQYc",
  "https://youtu.be/m0V0au7G-GQ",
  "https://www.youtube.com/watch?v=3F1MTubcVHw",
  "https://www.youtube.com/watch?v=N3kawg79hC8",
  "https://youtu.be/Cren6zIhXAM",
  "https://www.youtube.com/watch?v=guQUP7MPOig",
  "https://www.youtube.com/watch?v=MUINFs1Sp94",
  "https://youtu.be/mNT0Pj28f6A?t=73",
  "https://youtu.be/wlKfysovdbA",
  "https://youtu.be/rI80A61sj7I",
  "https://www.youtube.com/watch?v=rmh7vgGvRIQ",
  "https://youtu.be/B_CWqODKfEo",
  "https://youtu.be/a49Gi8FLPUk",
  "https://youtu.be/-bX58nt8DTU",
  "https://youtu.be/Kf8Jf8dzUDg",
  "https://www.youtube.com/watch?v=HRr8GRgOGAc",
  "https://www.youtube.com/watch?v=6RFOkS4jOh8",
  "https://youtu.be/7X09Hi8aaps",
  "https://www.youtube.com/watch?v=G2ztBRPKfkU",
  "https://www.youtube.com/watch?v=Uw7jrQQK3Mc",
  "https://www.youtube.com/watch?v=oAbtFHgpmb0",
  "https://youtu.be/dO4FpYQa41Q?t=37",
  "https://youtu.be/W4p8Dsuds7c",
  "https://www.youtube.com/watch?v=_StT8laRglw&feature=youtu.be",
  "https://www.youtube.com/watch?v=8EQ17_B7kug",
  "https://youtu.be/JelMQgsp1_8",
  "https://youtu.be/RxsX2lmi9Ek",
  "https://www.youtube.com/watch?v=7bILfpSNwVU",
  "https://youtu.be/_W9tiefIBvY",
  "https://youtu.be/jv6YlKnNXPs",
  "https://www.youtube.com/watch?v=6cDdvFQIAfM",
  "https://www.youtube.com/watch?v=jbi0uQ89hFQ",
  "https://www.youtube.com/watch?v=wm3R2ypn1fk",
  "https://youtu.be/g-79rP2vNuo",
  "https://youtu.be/ng-8lqGMfAg",
  "https://youtu.be/ykf9M3eZ8iY",
  "https://youtu.be/9jKdiL3t9zs",
  "https://www.youtube.com/watch?v=ZyEKUfwY3oo",
  "https://www.youtube.com/watch?v=jKR4saX1YKY",
  "https://youtu.be/QeEbOmwX1eY",
  "https://youtu.be/IQvsNY64UYg",
  "https://www.youtube.com/watch?v=42tD3FW7ny8",
  "https://www.youtube.com/watch?v=AyB7mgo4XjI",
  "https://www.youtube.com/watch?v=MzdLLpf9y9o",
  "https://youtu.be/GzUYgSwe1os",
  "https://www.youtube.com/watch?v=WuefmmQysaY",
  "https://youtu.be/QGGClRowfcw",
  "https://youtu.be/idQ4jrr8lN0",
  "https://youtu.be/Q878mpJnCDc",
  "https://www.youtube.com/watch?v=yNuIK1hnmzE",
  "https://youtu.be/YTkvr_EC2LA",
  "https://www.youtube.com/watch?v=ggm6uVxlOm0",
  "https://www.youtube.com/watch?v=h4twYqvssu0",
  "https://www.youtube.com/watch?v=hpQQohcHk9Q",
  "https://youtu.be/PfDFeqPutZA",
  "https://youtu.be/gZ-Ywd2GqUo",
  "https://youtu.be/32Hp_3SmV3Q",
  "https://www.youtube.com/watch?v=_ooY2VFyogE",
  "https://www.youtube.com/watch?v=Fcu5ZV_Ooj0",
  "https://www.youtube.com/watch?v=HfwPL-bd_mk",
  "https://youtu.be/vfMn1m0TT2U",
  "https://www.youtube.com/embed/fNaHRxFwiKo?end=30",
  "https://www.youtube.com/watch?v=Po3AYQvIY8k",
  "https://www.youtube.com/attribution_link?a=nCVczx-hyYY&u=%2Fwatch%3Fv%3DfwaBI9uByLE%26feature%3Dshare",
  "https://youtu.be/UvazGwBxqCs",
  "https://www.youtube.com/watch?v=tJdgErAfiRQ",
  "https://youtu.be/GqnwJdNjzzk",
  "https://www.youtube.com/watch?v=jzkONMcwPEE",
  "https://www.youtube.com/watch?v=bbEilXgam38",
  "https://youtu.be/c2wpIeZDrHE",
  "https://www.youtube.com/watch?v=srDM5LJlkrw",
  "https://www.youtube.com/watch?v=slVqNISUAWY",
  "https://youtu.be/auZrENOILpQ?t=24",
  "https://www.youtube.com/watch?v=pFlc_jizgl0",
  "https://www.youtube.com/watch?v=1kJO_L9gr5Y&feature=youtu.be",
  "https://www.youtube.com/watch?v=LP0HYIkHs2Q",
  "https://www.youtube.com/watch?v=1P0yfq2wDvU",
  "https://www.youtube.com/watch?v=ACXT1Np6CFs",
  "https://youtu.be/7-CZYkkOHkY",
  "https://www.youtube.com/watch?v=jSZwtrnKxf4",
  "https://www.youtube.com/watch?v=IzGM9xIIpFg",
  "https://youtu.be/Ti4sqG85FU4",
  "https://www.youtube.com/watch?v=pJTGAtLH7Hw",
  "https://youtu.be/xWq-ZPMhai8",
  "https://youtu.be/q6zKBSyxxL8?t=25",
  "https://www.youtube.com/watch?v=dxaJoQnU_hc",
  "https://www.youtube.com/watch?v=ZLAj4-FKnO4",
  "https://www.youtube.com/watch?v=FNdkTWiXaQM",
  "https://youtu.be/uDvZzRx3mcY",
  "https://www.youtube.com/watch?v=2WWNBjz_rxs",
  "https://www.youtube.com/watch?v=dANKqxonWsk",
  "https://youtu.be/f02GKR7SahQ",
  "https://www.youtube.com/watch?v=sfqjbFlB2dM",
  "https://www.youtube.com/watch?v=EoESIbM0vJg",
  "https://youtu.be/xt2OdUfYZbk",
  "https://m.youtube.com/watch?feature=youtu.be&v=iQKCDu2mPN0&utm_source=share&utm_medium=ios_app&utm_name=iossmf",
  "https://youtu.be/8eQw-HYZf6c",
  "https://www.youtube.com/watch?v=YEULqQIayxs",
  "https://www.youtube.com/watch?v=pAt2QyKm4oA",
  "https://www.youtube.com/watch?v=ceckCJVyFRw&feature=youtu.be",
  "https://www.youtube.com/watch?v=nzeYP0ULZS0",
  "https://www.youtube.com/watch?v=6t6rJFC53a4&feature=youtu.be",
  "https://youtu.be/2Ro2wOqh6A8",
  "https://youtu.be/cKu_22WpH4k",
  "https://youtu.be/xrvs420WeoI",
  "https://youtu.be/k1h8skB9X_c",
  "https://youtu.be/bHmdGZp8LuY",
  "https://www.youtube.com/watch?v=hINKKjtLJLY",
  "https://youtu.be/yMDz4f7lsEQ",
  "https://www.youtube.com/watch?v=Z5HMZzbMgqI",
  "https://www.youtube.com/watch?v=E3o10Qcb9BY&t=7",
  "https://www.youtube.com/watch?v=7LVcI-DQdYA",
  "https://www.youtube.com/watch?v=yY2iHYRLKFQ",
  "https://youtu.be/1mGqjWQwmMI",
  "https://www.youtube.com/watch?v=FXA9PkGvVAg",
  "https://www.youtube.com/watch?v=h8RpEqD1Ys8",
  "https://youtu.be/pGLxCJa4gV8",
  "https://youtu.be/LlCEmPF4-V0",
  "https://youtu.be/qvqI-Pp5KVw",
  "https://www.youtube.com/watch?v=-5zSEJMNYhE",
  "https://www.youtube.com/watch?v=-bWqcKzbQBY",
  "https://youtu.be/aBhO2IRNk1c",
  "https://youtu.be/8tonxd_9_lY",
  "https://www.youtube.com/watch?v=cMNvYJ6O_Ks",
  "https://www.youtube.com/watch?v=82pVND3efEc",
  "https://youtu.be/YC6krjjbwT8",
  "https://youtu.be/zDgc3LTiF9U",
  "https://youtu.be/79ijDo9uFe0",
  "https://youtu.be/hcOY-bIz90U?t=2",
  "https://youtu.be/bGL893BqLMY",
  "https://youtu.be/-kJ-13T26kM",
  "https://youtu.be/iJpIWDg80rY",
  "https://youtu.be/0ySrzYL0tmU",
  "https://youtu.be/lUDrbfHqtAM?t=63",
  "https://www.youtube.com/watch?v=pM6q4K4_H4g",
  "https://youtu.be/rA-_ZH1ggNE",
  "https://youtu.be/eZiDlU1qgBE",
  "https://youtu.be/qPttGbeJ0KE",
  "https://youtu.be/ojWhwK5pKHo",
  "https://youtu.be/vzjrGsuAycw",
  "https://youtu.be/pZCN2T1XDt8",
  "https://youtu.be/EwAEn2MGMv0",
  "https://youtu.be/XfgN-EzthJM",
  "https://youtu.be/qACwT88BtIA",
  "https://youtu.be/MDO9a9ntQ4M",
  "https://youtu.be/-dw_cKOTQq8",
  "https://youtu.be/gIF0UCFd3FM",
  "https://www.youtube.com/watch?feature=youtu.be&v=p5lD9HYa9QE",
  "https://www.youtube.com/watch?v=owZ_q01YhdE",
  "https://www.youtube.com/watch?v=K8DBs0QLqq4&feature=youtu.be",
  "https://www.youtube.com/watch?v=hhgwbIC9v_w",
  "https://youtu.be/1btO-pkvJ48",
  "https://www.youtube.com/watch?v=bVKNU_20yaw",
  "https://www.youtube.com/watch?v=wzx8Qs4VXBw",
  "https://youtu.be/LPULpNhhwhc",
  "https://youtu.be/Bb5eHXavk34",
  "https://youtu.be/f5T7ZeZr64Q",
  "https://www.youtube.com/watch?v=NLYXvJrPObE",
  "https://www.youtube.com/watch?v=z0eSyLb6FE0",
  "https://www.youtube.com/watch?v=SdrPS5VId4I",
  "https://www.youtube.com/watch?v=jns9NHhzGeM",
  "https://www.youtube.com/watch?v=21P66iNXJYc",
  "https://www.youtube.com/watch?v=qCBwm94esUo",
  "https://youtu.be/3LxjTOlGbzg",
  "https://www.youtube.com/watch?v=_wEaNFab4Q8",
  "https://www.youtube.com/watch?v=BFLONGA-wuM",
  "https://youtu.be/uysPDrhYzyM",
  "https://youtu.be/lcPv5ejNbKw",
  "https://youtu.be/aCmb3GGFn54",
  "https://www.youtube.com/watch?v=zr9p6qhH3CA&feature=youtu.be",
  "https://www.youtube.com/watch?v=aolV3LsIqG4&feature=youtu.be",
  "https://www.youtube.com/watch?v=hKuTlSv-_8M",
  "https://youtu.be/76GVampJ3Lk",
  "https://www.youtube.com/watch?v=IrGurGUzBII",
  "https://youtu.be/LQRQSNWvcT0",
  "https://www.youtube.com/watch?v=ORiUP1ZPLB0",
  "https://www.youtube.com/watch?v=FTrxDBDBOHU",
  "https://youtu.be/LI2mrdAOnUs",
  "https://www.youtube.com/watch?v=mUckUIkd4xc&feature=youtu.be",
  "https://youtu.be/3HlTd1H4xwQ",
  "https://www.youtube.com/watch?v=pUB_QptuSms",
  "https://www.youtube.com/watch?v=Pu59BeK9fBU",
  "https://www.youtube.com/watch?v=Tff2PBatf2Y",
  "https://youtu.be/fZhRZIqJy-c",
  "https://youtu.be/N7jGe5q-tMQ",
  "https://youtu.be/Wjx4-7NM-sE",
  "https://www.youtube.com/watch?v=hTQ3fTsJe50",
  "https://youtu.be/zQ0EGk0ZcnA",
  "https://youtu.be/y7TIv8xXjUw",
  "https://www.youtube.com/watch?v=ioZ9EUUIH5o",
  "https://www.youtube.com/watch?v=j2pq2qPq-Dc",
  "https://www.youtube.com/watch?v=v5fZYJe30ec",
  "https://www.youtube.com/watch?v=re_KT1g4LKg",
  "https://www.youtube.com/watch?v=1GTzHd1h47o",
  "https://youtu.be/XqMk90MfdB4",
  "https://www.youtube.com/watch?v=BScrP-lW60E",
  "https://youtu.be/8yJpGQ3Pfp4",
  "https://youtu.be/CvCSUEbAmpQ",
  "https://www.youtube.com/watch?v=DHQRZXM-4xI",
  "https://www.youtube.com/watch?v=bI6SPBFCqcQ",
  "https://www.youtube.com/watch?v=_AOqSGYRJzE",
  "https://www.youtube.com/watch?v=IRGG2dyi4Fc",
  "https://www.youtube.com/watch?v=pNwx8oQ4zgg",
  "https://youtu.be/dSbIeyjf2CE",
  "https://youtu.be/2cncNkRoAgA",
  "https://www.youtube.com/watch?v=eOR2KbFbAHU",
  "https://www.youtube.com/watch?v=DuL5sEQJwJ4",
  "https://youtu.be/8inTN4vkcwo",
  "https://youtu.be/DQlzminapfg",
  "https://youtu.be/qD1sSiTPu-M?t=690",
  "https://youtu.be/nwhDB1a0SCk",
  "https://youtu.be/Qu1LLBwl-iM",
  "https://youtu.be/ZWR_yD9K4oU",
  "https://youtu.be/cFV9Fn_pIH0?t=1",
  "https://youtu.be/GxP7rCCCaUo",
  "https://www.youtube.com/watch?v=G9llRVS8jqc",
  "https://youtu.be/O5AXC5lGPmo",
  "https://www.youtube.com/watch?v=vCSP1THD9cA",
  "https://youtu.be/3kuCkMUSyZg",
  "https://www.youtube.com/watch?v=BYPJ-2A6aYY",
  "https://www.youtube.com/watch?v=-Lbu3z_7a30",
  "https://www.youtube.com/watch?v=TF5nMmOuofI",
  "https://youtu.be/PIPpSNb0ZZo",
  "https://youtu.be/g8954vn_8Fo",
  "https://www.youtube.com/watch?v=OE2NPmqZ9nM",
  "https://youtu.be/vdAKxilAXIc",
  "https://www.youtube.com/watch?v=glM8zQBnWP8",
  "https://youtu.be/G38oMRe58sI",
  "https://youtu.be/XACsSOJzuB8",
  "https://youtu.be/U5eQ6e4M30w",
  "https://youtu.be/kQ7dbi1BSeY",
  "https://www.youtube.com/watch?v=3ZTj0N9Tw6w",
  "https://www.youtube.com/watch?v=kjABWRmwanM",
  "https://youtu.be/-8i6ZbepA08",
  "https://www.youtube.com/watch?v=R0O4bAlgfF0",
  "https://www.youtube.com/watch?v=G_SILs9t0dg&feature=youtu.be",
  "https://www.youtube.com/watch?v=Z0H-WOmyT50",
  "https://www.youtube.com/watch?v=mdQ2tWM7fwk",
  "https://www.youtube.com/watch?v=hS_MXJ32pz8",
  "https://www.youtube.com/watch?v=2B2hAcrY4Tc",
  "https://youtu.be/RFpiF0ADY1o",
  "https://www.youtube.com/watch?v=y0vsPqLJODE",
  "https://www.youtube.com/watch?v=brhtunxgzrE",
  "https://www.youtube.com/watch?v=sFD1keaBLo0",
  "https://www.youtube.com/watch?v=1KMsNyVjBpo&feature=youtu.be",
  "https://www.youtube.com/watch?v=Y9tTtzveLus",
  "https://www.youtube.com/watch?v=a7TGOyMFIkY&feature=youtu.be",
  "https://www.youtube.com/watch?v=4vl9pOOKCVE",
  "https://www.youtube.com/watch?v=xWM_Bv5yVHk",
  "https://youtu.be/2vHWsS5Kcd8",
  "https://youtu.be/snBcv6hBi84",
  "https://youtu.be/bGcwLPr2Wt0",
  "https://www.youtube.com/watch?v=tAp9md9Amzg",
  "https://www.youtube.com/watch?v=CZuqRL9_oCE",
  "https://www.youtube.com/watch?v=GrUG5S98N_g",
  "https://www.youtube.com/watch?v=T_2Rv7rm3Gk&feature=youtu.be",
  "https://www.youtube.com/watch?v=lhJaE8hC0SQ",
  "https://www.youtube.com/watch?v=w_MSFkZHNi4",
  "https://youtu.be/rKeGms2I1AU",
  "https://www.youtube.com/watch?v=NPn3t1Jwfp4",
  "https://www.youtube.com/watch?v=9_zxj1pIQXE",
  "https://youtu.be/O62t2hIuE-U",
  "https://www.youtube.com/watch?v=--keubHnjlc",
  "https://www.youtube.com/watch?v=v5RZ8k6iQik",
  "https://youtu.be/P4HLBfTcAUg",
  "https://www.youtube.com/watch?v=R82yvQ72vJo",
  "https://youtu.be/H9WUFUCCIEs?t=2",
  "https://www.youtube.com/watch?v=-S0cUJErI6E",
  "https://youtu.be/Vc-DOgodyDA",
  "https://www.youtube.com/watch?v=jM2V0WprHPY",
  "https://youtu.be/7hD61wWW8p8",
  "https://youtu.be/XKWQCiWn5CU",
  "https://www.youtube.com/watch?v=EhF0hfYz0mY",
  "https://www.youtube.com/watch?v=JmZicwX1eCI",
  "https://www.youtube.com/watch?v=16QY3ixMi7M",
  "https://www.youtube.com/watch?v=2DqSIvhOEOE",
  "https://www.youtube.com/watch?v=yVX0j8Gn01I",
  "https://www.youtube.com/watch?v=AYcxnzlC-t8",
  "https://www.youtube.com/watch?v=CL7XCCnxHbc",
  "https://youtu.be/g-RixPKLdUE",
  "https://www.youtube.com/watch?v=T9iYm4uJs3E",
  "https://youtu.be/9B098m0X1a8",
  "https://www.youtube.com/watch?v=LW1FtSuz-x8",
  "https://youtu.be/Eiv0e8_ED_c",
  "https://www.youtube.com/watch?v=gJvBU9o9P_w",
  "https://www.youtube.com/watch?v=hwGjE8eqxsg",
  "https://www.youtube.com/watch?v=3JnbcpjqK7M",
  "https://youtu.be/bfdJYBwlzgI?t=165",
  "https://youtu.be/jsCCzkaB9pY?list=PLX4VxArOaMKosMR7-Ri_VlfzixR3UnLnp&t=113",
  "https://youtu.be/dh1FZMGCTPk?list=PLX4VxArOaMKosMR7-Ri_VlfzixR3UnLnp&t=14",
  "https://www.youtube.com/watch?v=_OF964peJBE",
  "https://www.youtube.com/watch?v=jL0otKROJhY",
  "https://youtu.be/W2L4-KdNs-4",
  "https://www.youtube.com/watch?v=L1WqwGhes-c",
];
